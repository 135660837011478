import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import store from './store';
import './index.css';
import 'react-toastify/dist/ReactToastify.css'
import 'antd/dist/reset.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import ScrollToTop from './ScrollToTop';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['uz', 'oz'],
    fallbackLng: "uz",
    detection: {
      order: ['cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });


const loadingMarkup = (
  <div style={{ display: 'flex', textAlign: 'center', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
    <p>Loading...</p>
  </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback={loadingMarkup}>
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>
);
