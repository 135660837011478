import React from 'react'
import './not-found.css'
import { notFoundSvg } from '../../assets/icons/not-found-svg'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className='not-found-page container'>
            <div className='svg-wrap'>
                {notFoundSvg}
            </div>
            <div className='text-container'>
                <h1>Afsus 😔</h1>
                <p>Siz so‘ragan sahifa topilmadi</p>
            </div>
            <Link to='/'>
                <button style={{ width: '300px' }} className='green-btn'>Bosh sahifaga qaytish</button>
            </Link>
        </div>
    )
}

export default NotFound