import { Navigate } from "react-router-dom";
import Home from "./pages/home";
import AllCourses from "./pages/all-courses";
import CourseDetail from "./pages/course-detail";
import AllNews from "./pages/all-news";
import NewsDetail from "./pages/news-detail";
import MyProfile from "./pages/my-profile";
import MyWallet from "./pages/my-wallet";
import MySocials from "./pages/my-socials";
import MyCourses from "./pages/my-courses";
import ViewCourse from "./pages/view-course";
import InstructorDetail from "./pages/instructor-detail";
import MakePayment from "./pages/make-payment";
import NotFound from "./pages/not-found";

export const loginRouters = [
    { path: '/', element: <Home /> },
    { path: '/all-courses', element: <AllCourses /> },
    { path: '/course-detail/:id', element: <CourseDetail /> },
    { path: '/all-news', element: <AllNews /> },
    { path: '/all-news/news-detail/:id', element: <NewsDetail /> },
    { path: '/my-profile', element: <MyProfile /> },
    { path: '/instructor-detail/:instructorId', element: <InstructorDetail /> },
    { path: '/not-found', element: <NotFound /> },
    { path: '*', element: <Navigate to='/not-found' /> },
]

export const userRouters = [
    { path: '/', element: <Home /> },
    { path: '/all-courses', element: <AllCourses /> },
    { path: '/course-detail/:id', element: <CourseDetail /> },
    { path: '/all-news', element: <AllNews /> },
    { path: '/all-news/news-detail/:id', element: <NewsDetail /> },
    { path: '/my-profile', element: <MyProfile /> },
    { path: '/my-courses', element: <MyCourses /> },
    { path: '/my-wallet', element: <MyWallet /> },
    { path: '/my-socials', element: <MySocials /> },
    { path: '/view-course/:courseId', element: <ViewCourse /> },
    { path: '/instructor-detail/:instructorId', element: <InstructorDetail /> },
    { path: '/make-payment/:id', element: <MakePayment /> },
    { path: '/not-found', element: <NotFound /> },
    { path: '*', element: <Navigate to='/not-found' /> },
]