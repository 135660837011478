import { Button, Col } from 'antd'
import React from 'react'
import { Rate } from 'antd';
import { StarFilled } from '@ant-design/icons'
import './course.css'
import { Link, useNavigate } from 'react-router-dom';
import { reFormat } from '../../utils/reFormat';
import { useTranslation } from 'react-i18next';

const Course = (props) => {
    const { course, course_progress, xl, lg, md, sm, xs, enrolledId } = props

    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <Col
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            style={{ height: 'min-content' }}
        >
            <div className='one-course'>
                <div>
                    <Link to={`/course-detail/${course?.id}`}>
                        <div className='bg-wrap'>
                            <div className='bg' style={{ backgroundImage: `url(${course?.image})` }}></div>
                            <div className='status' style={{ background: course?.course_type === "FREE" ? 'var(--green)' : 'var(--yellow)' }}>
                                {course?.course_type === "FREE" ? t('common.free') : `${reFormat(course?.price_after_discount)} so'm`}
                            </div>
                        </div>
                    </Link>
                    <Link to={`/course-detail/${course?.id}`}>
                        <p>{course?.title}</p>
                    </Link>
                    <br />
                    <div className='course-about'>
                        <div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 9V11.382C12 11.7607 12.214 12.107 12.5528 12.2764L14 13M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>

                            <span className='span'>{course?.duration}</span>
                        </div>
                        <div>
                            <Rate
                                value={course?.total_reviews_avg}
                                allowHalf
                                disabled={true}
                                width='12px'
                                character={<StarFilled />}
                            />
                            <span className='span'>{course?.total_reviews_count} {t('detail-page.comments')}</span>
                        </div>
                    </div>
                    <div className='teacher-about'>
                        <div className='left' onClick={() => navigate(`/instructor-detail/${course?.instructor?.id}`)}>
                            <div className='bg-teacher' style={{ backgroundImage: `url(${course?.instructor?.image})` }}></div>
                            <div className='texts-wrap'>
                                <h2>{course?.instructor?.instructor_name}</h2>
                                <p>{course?.instructor?.profession}</p>
                            </div>
                        </div>
                        <div style={{ whiteSpace: 'nowrap' }}>{course?.instructor?.students_count} {t('course.pupils')}</div>
                    </div>

                    {
                        course_progress === 0 || course_progress > 0 ? (
                            <div className='course-progress'>
                                <div style={{ width: `${course_progress}%` }}></div>
                                <p>{course_progress}%</p>
                            </div>
                        ) : null
                    }
                </div>
                {
                    enrolledId ? (
                        <Link to={`/view-course/${enrolledId}`}>
                            <Button className='white-btn'>{t('course.continue')}</Button>
                        </Link>
                    ) : (
                        <Link to={`/course-detail/${course?.id}`}>
                            <Button className='white-btn'>{t('course.about')} / {t('course.start')}</Button>
                        </Link>
                    )
                }
            </div>
        </Col>
    )
}

export default Course