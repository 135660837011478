import React, { useEffect, useState } from 'react'
import './my-socials.css'
import Sidebar from '../../components/sidebar'
import { Col, Form, Input, Row, Spin } from 'antd'
import http from '../../utils/axiosInterceptors'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const MySocials = () => {
    const { t } = useTranslation()

    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const getMySocials = async () => {
        try {
            const response = await http.get('/student/social-media/')
            console.log(response?.data)
        } catch (error) {
            console.log(error)
        }
    }

    const onFinish = async (values) => {
        setIsLoading(true)
        try {
            await http.post('/student/social-media/', values)
            toast.success('Muvaffaqqiyatli saqlandi!')
        } catch (error) {
            toast.error("To'g'ri URL manzilni kiriting!")
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getMySocials()
    }, [])

    return (
        <div className='my-socials-page container'>
            <Sidebar />
            <div className='right box'>
                <h1>{t('profil.my-socials')}</h1>
                <p>{t('profil.socials-text')}</p>
                <hr />

                <Form
                    name="My Socials"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    labelCol={{ span: 24 }}
                    form={form}
                >
                    <Row
                        gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 32,
                        }}
                    >
                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Telegram"
                                name="tg_link"
                            >
                                <Input
                                    placeholder={t('profil.link')}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Facebook"
                                name="fb_link"
                            >
                                <Input
                                    placeholder={t('profil.link')}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Instagram"
                                name="ig_link"
                            >
                                <Input
                                    placeholder={t('profil.link')}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={12} xs={24}>
                            <Form.Item
                                label="Twitter"
                                name="tw_link"
                            >
                                <Input
                                    placeholder={t('profil.link')}
                                    size='large'
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            span={24}
                        >
                            <button className='yellow-btn' disabled={isLoading}>
                                {isLoading ? <Spin /> : t('profil.save')}
                            </button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default MySocials