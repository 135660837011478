import { Modal } from 'antd'
import React from 'react'

const Result = (props) => {
    const { isOpenModal, setIsOpenModal, finishCourseLesson, resultsInfo, finishLoading, quizType } = props

    const isSuccess = resultsInfo?.pass_percentage >= resultsInfo?.minumum_pass_percentage

    return (
        <Modal
            open={isOpenModal}
            footer={null}
            centered
            width={700}
            closeIcon={null}
        >
            <div className='results-modal'>
                <h1>Test natijalari</h1>
                <p>Bu yerda siz o‘tgan testingiz javobini ko‘rishingiz mumkin</p>
                <div className='texts'>
                    <h4>Sizning natijangiz: <b>{resultsInfo?.pass_percentage}%</b></h4>
                    <h4>O‘tish natijasi: <b>{resultsInfo?.minumum_pass_percentage}%</b></h4>
                    <h4>To‘g‘ri javoblar soni: <b>{resultsInfo?.total_passed} / {resultsInfo?.total}</b></h4>
                </div>
                {
                    quizType === "main" ? (
                        isSuccess ? (
                            <div className='texts'>
                                <h4>Tabriklaymiz, Siz kursni muvaffaqiyatli yakunladingiz!</h4>
                                <h4>Sertifikatni yuklab olishingiz va izohlaringizni qoldirishingiz mumkin!</h4>
                            </div>
                        ) : (
                            <div className='texts'>
                                <h4>Afsus, Siz testdan o'ta olmadingiz!</h4>
                                <h4>Sizda testni qaytadan yechish imkoniyati mavjud!</h4>
                            </div>
                        )
                    ) : null
                }
                <div className='btn-wrap'>
                    <button className='white-btn' onClick={() => {
                        setIsOpenModal(false)
                        window.location.reload()
                    }}>Qaytadan urinib ko'rish</button>
                    {
                        quizType === "main" ? (
                            <button className='green-btn' disabled={!isSuccess} onClick={finishCourseLesson}>
                                {finishLoading ? "Iltimos kuting..." : "Yakunlash"}
                            </button>
                        ) : (
                            <button className='green-btn' disabled={!isSuccess} onClick={finishCourseLesson}>
                                {finishLoading ? "Iltimos kuting..." : "Keyingi dars"}
                            </button>
                        )
                    }
                </div>
            </div>
        </Modal>
    )
}

export default Result