import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar'
import './my-wallet.css'
import http from '../../utils/axiosInterceptors'
import { reFormat } from '../../utils/reFormat'
import { Spin, Table } from 'antd'
import { Collapse } from 'antd'
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next'

const MyWallet = () => {
    const { t } = useTranslation()

    const panelStyle = {
        background: "#F8F8F8",
        borderRadius: "8px",
        border: 'none',
        color: "#0C0C0C",
        fontSize: "16px",
        fontWeight: "500"
    }
    const [walletInfo, setWalletInfo] = useState({})
    const [transactionsList, setTransactionsList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)

    const getItems = (panelStyle) => [
        {
            key: '1',
            label: t('profil.video-text'),
            children: [
                <div key={uuidv4()}>
                    <iframe src="https://www.youtube.com/embed/MIyoMHtdi4s" title="O‘simliklar karantini va himoyasi agentligi faoliyati: yutuq va rejalar" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            ],
            style: panelStyle,
        },
    ]

    const columns = [
        {
            title: `#`,
            key: 'numberOfRow',
            fixed: 'left',
            align: 'center',
            width: 60,
            render: (text, obj, index) => {
                return (
                    <span> {(pageNumber - 1) * 10 + index + 1} </span>
                )
            },
            checked: true,
        },
        {
            title: t('profil.date'),
            dataIndex: "payment_date",
            key: "date",
            align: "center",
            width: 100
        },
        {
            title: t('profil.price'),
            dataIndex: "payment_amount",
            key: "price",
            align: "center",
            render: (row) => <>{reFormat(row)}</>,
            width: 100
        },
        {
            title: t('profil.status'),
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 100
        },
        {
            title: t('profil.payment-method'),
            dataIndex: "payment_method",
            key: "payment_method",
            align: "center",
            width: 150
        },
        {
            title: t('profil.tr-type'),
            dataIndex: "transaction_type",
            key: "transaction_type",
            align: "center",
            width: 100
        },
    ]

    const getMyWallet = async () => {
        setIsLoading(true)
        try {
            const response = await http.get("/payment/wallet/get")
            setWalletInfo(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getTransactions = async () => {
        setIsLoadingTransactions(true)
        try {
            const response = await http.get(`/payment/transactions/list/`)
            setTransactionsList(response?.data?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingTransactions(false)
        }
    }

    useEffect(() => {
        getMyWallet()
        getTransactions()
    }, [])

    return (
        <div className='my-wallet-page container'>
            <Sidebar />
            <div className='right box'>
                <h1>{t('profil.my-balans')}</h1>
                <p>{t('profil.balans-text')}</p>
                {
                    isLoading ? <Spin size='large' style={{ marginTop: '20px' }} /> : (
                        <div className='wallet-card'>
                            <span>{walletInfo?.wallet_number}</span>
                            <h6>
                                <strong>{reFormat(walletInfo?.balance)} </strong>
                                so'm
                            </h6>
                            <em>{walletInfo?.created_at}</em>
                        </div>
                    )
                }

                <hr />
                <h1>{t('profil.transactions')}</h1>
                <Table
                    columns={columns}
                    dataSource={transactionsList}
                    rowKey={"id"}
                    scroll={{
                        x: 'scroll',
                    }}
                    size='small'
                    loading={isLoadingTransactions}
                    pagination={{ current: pageNumber, onChange: (e) => setPageNumber(e) }}
                />

                <hr />
                <h1>{t('profil.deposit')}</h1>
                <Collapse
                    expandIconPosition='end'
                    bordered={false}
                    defaultActiveKey={['1']}
                    style={{
                        background: "transparent",
                    }}
                    items={getItems(panelStyle)}
                />
            </div>
        </div>
    )
}

export default MyWallet