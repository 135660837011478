// export const BASE_URL = "https://ruling-spider-usefully.ngrok-free.app/api/v1/";
export const BASE_URL = "https://edukarantin.uz/api/v1/";
// 
export const SITE_URL = "https://edukarantin.uz"
// export const SITE_URL = "http://localhost:3000"
// export const SITE_URL = "https://a4aa-45-150-25-102.ngrok-free.app"
// export const SITE_URL = "https://edu-platform-front.vercel.app/"

export const CLIENT_ID = "edukarantin_uz"

// export const IMAGE_URL = "https://ruling-spider-usefully.ngrok-free.app/"
export const IMAGE_URL = "https://edukarantin.uz/"