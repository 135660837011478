// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/course-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.one-comment {
  width: 100%;
  border-radius: 16px;
  background: #fff;
  padding: 30px;
  position: relative;
  transition: 0.3s;
}

.one-comment:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.one-comment .bg {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  margin: 0px 20px 10px 0px;
}

.one-comment p {
  color: #0f0f0f;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.one-comment span {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 576px) {
  .one-comment .bg {
    width: 72px;
    height: 72px;
    margin: 0px 15px 5px 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/comment/comment.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B;uCACqC;AACvC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yDAA0D;EAC1D,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,wBAAwB;EAC1B;AACF","sourcesContent":[".one-comment {\n  width: 100%;\n  border-radius: 16px;\n  background: #fff;\n  padding: 30px;\n  position: relative;\n  transition: 0.3s;\n}\n\n.one-comment:hover {\n  transform: translateY(-10px);\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,\n    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n}\n\n.one-comment .bg {\n  width: 96px;\n  height: 96px;\n  border-radius: 50%;\n  background-image: url(\"../../assets/images/course-bg.png\");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  float: left;\n  margin: 0px 20px 10px 0px;\n}\n\n.one-comment p {\n  color: #0f0f0f;\n  font-family: Manrope;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 150%; /* 24px */\n}\n\n.one-comment span {\n  position: absolute;\n  right: 20px;\n  bottom: 20px;\n  color: #000;\n  font-size: 16px;\n  font-weight: 400;\n}\n\n@media (max-width: 576px) {\n  .one-comment .bg {\n    width: 72px;\n    height: 72px;\n    margin: 0px 15px 5px 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
