import { Col, Rate } from 'antd'
import React, { useState } from 'react'
import { StarFilled } from '@ant-design/icons'
import http from '../../../utils/axiosInterceptors'
import { toast } from 'react-toastify'

const Comment = ({ courseDetail }) => {
    const [rating, setRating] = useState(0)
    const [text, setText] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const postComment = async () => {
        setIsLoading(true)
        try {
            await http.post("cource/review/", {
                course: courseDetail?.course_id,
                rating,
                comment: text,
            })
            toast.success("Izoh qoldirganingiz uchun raxmat!")
        } catch (err) {
            toast.error("Xatolik sodir bo'ldi")
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className='comment-container'>
            <p>Bu yerda siz kursga baho va izohingizni qoldirishingiz mumkin </p>
            <Rate
                value={rating}
                onChange={e => setRating(e)}
                character={<StarFilled />}
                className='rate-icon'
            />
            <div className='wrap'>
                <label>Izohingiz</label>
                <textarea placeholder='O‘z izohingizni qoldiring' value={text} onChange={e => setText(e.target.value)}></textarea>
            </div>
            <Col
                span={8}
                style={{ marginLeft: 'auto' }}
            >
                <button className='green-btn' onClick={postComment} disabled={isLoading}>
                    {
                        isLoading ? "Iltimos kuting..." : "Izoh qoldirish"
                    }
                </button>
            </Col>
        </div>
    )
}

export default Comment