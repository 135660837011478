// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-socials-page {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
}

.my-socials-page .right {
  width: calc(100% - 464px);
  height: max-content;
}

.my-socials-page h1 {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 600;
}

.my-socials-page p {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.my-socials-page .right hr {
  margin: 24px 0;
}

.my-socials-page .right .ant-upload {
  margin-top: 24px;
}

@media (max-width: 1600px) {
  .my-socials-page .right {
    width: calc(100% - 375px);
    height: max-content;
  }
}

@media (max-width: 1400px) {
  .my-socials-page .right {
    width: calc(100% - 320px);
    height: max-content;
  }
  .my-socials-page h1 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .my-socials-page {
    flex-direction: column;
  }
  .my-socials-page .right {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/my-socials/my-socials.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,yBAAyB;IACzB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,yBAAyB;IACzB,mBAAmB;EACrB;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".my-socials-page {\n  min-height: 100vh;\n  display: flex;\n  justify-content: space-between;\n}\n\n.my-socials-page .right {\n  width: calc(100% - 464px);\n  height: max-content;\n}\n\n.my-socials-page h1 {\n  color: #0c0c0c;\n  font-size: 24px;\n  font-weight: 600;\n}\n\n.my-socials-page p {\n  color: #0c0c0c;\n  font-size: 16px;\n  font-weight: 400;\n  margin-bottom: 0;\n}\n\n.my-socials-page .right hr {\n  margin: 24px 0;\n}\n\n.my-socials-page .right .ant-upload {\n  margin-top: 24px;\n}\n\n@media (max-width: 1600px) {\n  .my-socials-page .right {\n    width: calc(100% - 375px);\n    height: max-content;\n  }\n}\n\n@media (max-width: 1400px) {\n  .my-socials-page .right {\n    width: calc(100% - 320px);\n    height: max-content;\n  }\n  .my-socials-page h1 {\n    font-size: 20px;\n  }\n}\n\n@media (max-width: 992px) {\n  .my-socials-page {\n    flex-direction: column;\n  }\n  .my-socials-page .right {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
