import React, { useEffect, useState } from 'react'
import './all-courses.css'
import { Col, Empty, Input, Pagination, Row, Select } from 'antd'
import Course from '../../components/course'
import http from '../../utils/axiosInterceptors'
import Loader from '../../components/loader'
import { SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

const AllCourses = () => {
    const { t } = useTranslation()

    const { search } = queryString.parse(window.location.search)
    const navigate = useNavigate()

    const [allCourses, setAllCourses] = useState([])
    const [isLoadingCourses, setIsLoadingCourses] = useState(false)
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [allCategories, setAllCategories] = useState([])
    const [selectedCategories, setSelectedCatogies] = useState([])
    const [searchInput, setSearchInput] = useState(search)
    const [courseType, setCourseType] = useState(null)
    const [orderingType, setOrderingType] = useState(null)

    const getCategories = async () => {
        setAllCategories([])
        try {
            const response = await http.get('/common/category/')
            response?.data?.forEach((c) => {
                setAllCategories(prev => [...prev, { label: c?.name, value: c?.id }])
            })
        } catch (err) {
            console.log(err)
        }
    }

    const getAllCourses = async () => {
        setIsLoadingCourses(true)
        setAllCourses([])
        try {
            const response = await http.get('/cource/list/', {
                params: {
                    page: currentPage,
                    pageSize: pageSize,
                    category: selectedCategories,
                    title__icontains: searchInput,
                    course_type: courseType,
                    ordering: orderingType
                }
            })
            setAllCourses(response?.data?.results)
            setCount(response?.data?.count)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingCourses(false)
        }
    }

    useEffect(() => {
        getAllCourses()

        // eslint-disable-next-line
    }, [pageSize, currentPage, selectedCategories, searchInput, courseType, orderingType])

    useEffect(() => {
        getCategories()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        navigate(`/all-courses?${queryString.stringify({ search: searchInput })}`)
        // eslint-disable-next-line
    }, [searchInput])

    return (
        <div className='all-course-page container'>
            <div className='top-navigator'>
                <Link to="/">
                    <div>
                        {t('navbar.home')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <div className='active'>
                    {t('navbar.all-courses')}
                </div>
            </div>

            <Row className='filter-courses' gutter={[24, 24]}>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Input
                        addonBefore={<SearchOutlined />}
                        size='large'
                        placeholder={t('home.search')}
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                        allowClear
                    />
                </Col>

                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Select
                        placeholder={t('common.category')}
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        mode='multiple'
                        onChange={(e) => setSelectedCatogies(e)}
                        options={allCategories}
                    />
                </Col>

                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Select
                        placeholder={t('common.sort-type')}
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        options={[
                            { label: t('common.alifbo'), value: 'title' },
                            { label: t('common.new'), value: 'created_at' },
                            { label: t('common.cheap'), value: 'price' },
                            { label: t('common.expencive'), value: '-price' },
                        ]}
                        value={orderingType}
                        onChange={e => setOrderingType(e)}
                    />
                </Col>

                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Select
                        placeholder={t('common.price')}
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        value={courseType}
                        onChange={(e) => setCourseType(e)}
                        options={[
                            { label: t('common.free'), value: 'FREE' },
                            { label: t('common.paid'), value: 'PAID' },
                        ]}
                    />
                </Col>
            </Row>

            {!isLoadingCourses && allCourses?.length === 0 ? <Empty /> : null}
            {
                isLoadingCourses ? <Loader /> : (
                    <>
                        <Row
                            gutter={[24, 24]}
                        >
                            {
                                allCourses?.map((item, index) => {
                                    return (
                                        <Course
                                            key={index}
                                            enrolledId={item?.enrolled_id}
                                            course={item}
                                            xl={8}
                                            lg={12}
                                            md={12}
                                            sm={24}
                                            xs={24}
                                        />
                                    )
                                })
                            }
                        </Row>
                        {
                            allCourses?.length === 0 ? null : (
                                <Pagination
                                    total={count}
                                    pageSize={pageSize}
                                    current={currentPage}
                                    onChange={(a, b) => {
                                        setCurrentPage(a)
                                        setPageSize(b)
                                    }}
                                />
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default AllCourses