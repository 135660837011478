import React, { useContext, useState } from 'react'
import logo from "../../assets/images/logo.png"
import './navbar.css'
import { Link } from 'react-router-dom'
import { CLIENT_ID, SITE_URL } from '../../constants'
import { useSelector } from 'react-redux'
import { UserContext } from '../../App'
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { Dropdown } from 'antd'
import i18next from 'i18next'
import Cookies from 'js-cookie'

const Navbar = () => {
    const { t } = useTranslation()
    const initialLang = Cookies.get('i18next') || 'uz'

    const location = window.location.pathname
    const { accessToken } = useSelector(state => state.auth)
    const isAuth = !!accessToken

    const { userData } = useContext(UserContext)

    const [isOpen, setIsOpen] = useState(false)

    const items = [
        {
            key: '1',
            label: (
                <div onClick={() => i18next.changeLanguage('uz')}>O'zbekcha</div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={() => i18next.changeLanguage('oz')}>Ўзбекча</div>
            ),
        },
    ]

    const langType = (type) => {
        switch (type) {
            case 'uz':
                return "O'zbekcha"
            case 'oz':
                return "Ўзбекча"
            default:
                return null
        }
    }

    return (
        <div className='navbar-wrap'>
            <div className='navbar-component container'>
                <div className='menu' onClick={() => setIsOpen(!isOpen)}>
                    <HiOutlineMenuAlt1 />
                </div>

                <div className='logo-wrap'>
                    <Link to='/'>
                        <img src={logo} alt='' className='logo' />
                    </Link>
                </div>

                <div className='links-wrap'>
                    <Link to='/' className={location === "/" ? 'active' : null}>{t('navbar.home')}</Link>
                    <Link to='/all-courses' className={location.includes('all-courses') ? 'active' : null}>{t('navbar.all-courses')}</Link>
                    <a href='https://karantinhimoya.uz/' target='_blank' rel='noreferrer'>{t('navbar.about')}</a>
                    <Link to='/all-news' className={location.includes('all-news') ? 'active' : null}>{t('navbar.news')}</Link>
                    <a href='https://t.me/agroinstitute' target='_blank' rel='noreferrer'>{t('navbar.help')}</a>
                </div>

                <div className='lang-container'>
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={'click'}
                    >
                        <div className='selected-lang'>
                            {langType(initialLang)}
                            <IoIosArrowDown style={{ marginLeft: '5px' }} />
                        </div>
                    </Dropdown>
                </div>
                {console.log(userData)}
                {
                    isAuth ? (
                        <Link to='/my-profile'>
                            <div className='user-wrap'>
                                {
                                    userData?.picture ? (
                                        <div className='user-bg' style={{ backgroundImage: `url(${userData?.picture})` }}></div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
                                            <path d="M37.1177 39.0976C35.1308 33.7835 30.0072 30 24 30C17.9928 30 12.8692 33.7835 10.8823 39.0976M37.1177 39.0976C41.3343 35.4309 44 30.0267 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 30.0267 6.66569 35.4309 10.8823 39.0976M37.1177 39.0976C33.6061 42.1513 29.019 44 24 44C18.981 44 14.3939 42.1513 10.8823 39.0976M30 18C30 21.3137 27.3137 24 24 24C20.6863 24 18 21.3137 18 18C18 14.6863 20.6863 12 24 12C27.3137 12 30 14.6863 30 18Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinejoin="round" />
                                        </svg>
                                    )
                                }
                                <h2>{userData?.user?.first_name} <br />{userData?.user?.last_name}</h2>
                            </div>
                        </Link>
                    ) : (
                        <Link to={`https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=${CLIENT_ID}&redirect_uri=${SITE_URL}/my-profile/&scope=${CLIENT_ID}&state=testState`}>
                            <button className='yellow-btn'>{t('navbar.login')}</button>
                        </Link>
                    )
                }
            </div>

            <div className={isOpen ? 'mobile-menu active' : 'mobile-menu'}>
                <div className='left'>
                    <IoMdClose className='icon' onClick={() => setIsOpen(false)} />

                    <Link to='/' className={location === "/" ? 'active' : null} onClick={() => setIsOpen(false)}>{t('navbar.home')}</Link>
                    <Link to='/all-courses' className={location.includes('all-courses') ? 'active' : null} onClick={() => setIsOpen(false)}>{t('navbar.all-courses')}</Link>
                    <a href='https://karantinhimoya.uz/' target='_blank' rel='noreferrer' onClick={() => setIsOpen(false)}>{t('navbar.about')}</a>
                    <Link to='/all-news' className={location.includes('all-news') ? 'active' : null} onClick={() => setIsOpen(false)}>{t('navbar.news')}</Link>
                    <a href='https://t.me/agroinstitute' target='_blank' rel='noreferrer' onClick={() => setIsOpen(false)}>{t('navbar.help')}</a>
                </div>
                <div className='right' onClick={() => setIsOpen(false)}></div>
            </div>
        </div>
    )
}

export default Navbar