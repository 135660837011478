// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-inner .warning-wrap.common {
  padding: 20px;
  background-color: hsla(0, 100%, 91%, 0.3);
  color: #d71b1b;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #d71b1b;
}

.offerta-modal .warning {
  padding: 0;
  text-align: center;
}

.offerta-modal .warning-icon {
  color: #d71b1b;
  font-size: 48px;
  margin: auto;
}

.offerta-modal .btn-wrap {
  text-align: center;
}

.offerta-modal .btn-wrap button.btn-red {
  background: #eb8d8d;
  width: 200px;
  height: 36px;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: not-allowed;
}

.offerta-modal .btn-wrap button.btn-active {
  background: #d71b1b;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/modals/offerta/offerta.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yCAAyC;EACzC,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".modal-inner .warning-wrap.common {\n  padding: 20px;\n  background-color: hsla(0, 100%, 91%, 0.3);\n  color: #d71b1b;\n  margin-bottom: 20px;\n  border-radius: 8px;\n  border: 1px solid #d71b1b;\n}\n\n.offerta-modal .warning {\n  padding: 0;\n  text-align: center;\n}\n\n.offerta-modal .warning-icon {\n  color: #d71b1b;\n  font-size: 48px;\n  margin: auto;\n}\n\n.offerta-modal .btn-wrap {\n  text-align: center;\n}\n\n.offerta-modal .btn-wrap button.btn-red {\n  background: #eb8d8d;\n  width: 200px;\n  height: 36px;\n  color: #fff;\n  border: none;\n  border-radius: 8px;\n  cursor: not-allowed;\n}\n\n.offerta-modal .btn-wrap button.btn-active {\n  background: #d71b1b;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
