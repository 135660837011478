// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.one-news {
  border-radius: 32px;
  /* background-image: url("../../assets/images/news.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00000020;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.one-news:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.one-news .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.4) 179.86%);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 24px;
}

.one-news .bottom h2 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.one-news .bottom > div {
  display: flex;
  justify-content: flex-end;
}

.one-news .bottom p {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
}

@media (max-width: 992px) {
  .one-news {
    border-radius: 24px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .one-news {
    border-radius: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/news/news.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2DAA2D;EAC3D,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;EAC3B,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B;uCACqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,4EAA4E;EAC5E,mCAA2B;UAA3B,2BAA2B;EAC3B,aAAa;AACf;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE;IACE,mBAAmB;IACnB,aAAa;EACf;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".one-news {\n  border-radius: 32px;\n  /* background-image: url(\"../../assets/images/news.png\"); */\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-color: #00000020;\n  width: 100%;\n  height: 400px;\n  position: relative;\n  overflow: hidden;\n  transition: 0.3s;\n}\n\n.one-news:hover {\n  transform: translateY(-10px);\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,\n    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n}\n\n.one-news .bottom {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.4) 179.86%);\n  backdrop-filter: blur(10px);\n  padding: 24px;\n}\n\n.one-news .bottom h2 {\n  color: #000;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.one-news .bottom > div {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.one-news .bottom p {\n  color: #888;\n  font-size: 14px;\n  font-weight: 400;\n  margin: 0;\n  margin-left: 10px;\n}\n\n@media (max-width: 992px) {\n  .one-news {\n    border-radius: 24px;\n    height: 300px;\n  }\n}\n\n@media (max-width: 768px) {\n  .one-news {\n    border-radius: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
