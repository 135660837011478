import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { IoIosWarning } from "react-icons/io";
import "./offerta.css"
import { toast } from 'react-toastify';

const Offerta = (props) => {
    const [isBtn, setIsBtn] = useState(false);
    const { isModalOpen, setIsModalOpen, setIsAcceptedOfferta } = props

    const closeModal = () => {
        if (isBtn) {
            setIsModalOpen(false)
            setIsAcceptedOfferta(true)
        } else {
            toast.warning("Iltimos, ogohlantirish bilan tanishing!");
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsBtn(true);
        }, 5000);
    }, [])

    return (
        <Modal
            open={isModalOpen}
            onOk={null}
            onCancel={null}
            centered
            footer={null}
            className='offerta-modal'
            width={780}
            closable={null}
        >
            <div className="langs-outer">
                <CountdownCircleTimer
                    isPlaying
                    duration={5}
                    isGrowing
                    colors="#036323"
                    size={40}
                    strokeWidth={6}
                >
                    {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
            </div>

            <h1 className="warning" style={{ textAlign: 'center' }}>
                <IoIosWarning className='warning-icon' />
            </h1>

            <div className="modal-inner warning">
                <div className="warning-wrap common">
                    <p>Vazirlar Mahkamasining 2018-yil 29-yanvardagi 65-sonli qaroriga muvofiq karantin ostidagi mahsulotlar fitosanitar nazoratdan o'tkazilib, karantin ko'rigi dalolatnomasi rasmiylashtirilganidan so'ng bojxona hujjatlarini rasmiylashtirishga asos bo'ladi.

                        <br />Mazkur talablarga rioya qilmaslik O'zbekiston Respublikasi Ma'muriy javobgarlik to'g'risidagi kodeksining 108 hamda 198-moddalarida nazarda tutilgan javobgarlikka asos bo'lishi haqida ogohlantiramiz.

                        <br />Hurmatli tadbirkor, unutmang, qonunni bilmaslik javobgarlikdan ozod qilmaydi!</p>
                </div>
            </div>

            <div className="btn-wrap warning">
                <button
                    type="button"
                    className={isBtn ? "btn-active btn-red" : "btn-red"}
                    onClick={closeModal}
                >
                    Tanishdim
                </button>
            </div>
        </Modal>
    )
}

export default Offerta