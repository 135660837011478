import { Col, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import http from '../../../utils/axiosInterceptors'
import queryString from 'query-string'
import parse from 'html-react-parser'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/loader'
import Result from './result'
import { toast } from 'react-toastify'

const Quiz = (props) => {
    const { courseSections, getCourseDetails } = props

    const params = useParams()
    const courseId = params.courseId

    const navigate = useNavigate()
    const { lessonId } = queryString.parse(window.location.search)

    const [isLoading, setIsLoading] = useState(false)
    const [lessonDetail, setLessonDetail] = useState({})
    const [allSelectedAnswers, setAllSelectedAnswers] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [resultsInfo, setResultsInfo] = useState({})
    const [endLoading, setEndLoading] = useState(false)
    const [finishLoading, setFinishLoading] = useState(false)
    const [quizCount, setQuizCount] = useState(0)

    const matchAnswers = (questionId, answer) => {
        let existingObj = allSelectedAnswers.find(obj => obj.quiz_id === questionId);
        if (existingObj) {
            existingObj.answer = answer
        } else {
            let newObj = {
                quiz_id: questionId,
                answer
            }
            allSelectedAnswers.push(newObj)
        }
        setAllSelectedAnswers([...allSelectedAnswers,])
    }

    const getCourseLessonDetails = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`/cource/lesson/detail/${lessonId}/`)
            setLessonDetail(response?.data)
            setQuizCount(response?.data?.quiz?.tests?.length)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const endQuiz = async () => {
        if (quizCount === allSelectedAnswers.length) {
            setEndLoading(true)
            try {
                const response = await http.post("/quiz/answer/", {
                    enrolled_course: courseId,
                    answers: allSelectedAnswers,
                })
                setResultsInfo(response?.data?.data)
                setIsOpenModal(true)
            } catch (error) {
                toast.error("Javoblarni to'liq belgilang")
            } finally {
                setEndLoading(false)
            }
        } else {
            toast.error("Barcha javoblarni belgilang!")
        }
    }

    const finishCourseLesson = async () => {
        setFinishLoading(true)
        try {
            const response = await http.post(`/cource/finish/`, {
                enrolled_course_id: courseId,
                lesson_id: lessonId,
            });
            const nextId = response?.data?.data?.next_lesson_id;
            if (nextId) {
                courseSections?.forEach(section => {
                    section?.lessons?.forEach(lesson => {
                        if (lesson?.id === nextId) {
                            navigate(`?${queryString.stringify({ lessonType: lesson?.lesson_type, lessonId: lesson?.id, sectionId: section?.id })}`)
                        }
                    })
                })
                setIsOpenModal(false)
                getCourseDetails()
            } else {
                navigate(`?${queryString.stringify({ lessonType: "main-quiz", lessonId: undefined, sectionId: undefined })}`)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFinishLoading(false)
        }
    };

    useEffect(() => {
        getCourseLessonDetails()

        // eslint-disable-next-line
    }, [])

    return (
        <div className='quiz-page'>
            {
                isLoading ? <Loader /> : (
                    <>
                        <h1>{lessonDetail?.quiz?.title}</h1>
                        <div>{lessonDetail?.quiz?.description ? parse(lessonDetail?.quiz?.description) : null}</div>
                        {
                            lessonDetail?.quiz?.tests?.map((item, index) => {
                                return (
                                    <div className='one-quiz' key={index}>
                                        <div className='top'>
                                            <h3><strong style={{ marginRight: '10px' }}>{index + 1}.</strong> {item?.question ? parse(item?.question) : null}</h3>
                                            <span>{item?.point} ball</span>
                                        </div>
                                        <div className='answers'>
                                            <Radio.Group
                                                onChange={(e) => matchAnswers(item?.id, e.target.value)}
                                                buttonStyle='solid'
                                            >
                                                {
                                                    item?.options?.map((option) => {
                                                        return (
                                                            <Radio key={Object.keys(option)} value={Object.keys(option)[0]}>{Object.keys(option)[0]}) {Object.values(option)}</Radio>
                                                        )
                                                    })
                                                }
                                            </Radio.Group>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <Col
                            span={8}
                            style={{ marginLeft: 'auto' }}
                        >
                            <button className='green-btn' onClick={endQuiz} disabled={endLoading}>
                                {endLoading ? "Iltimos kuting..." : "Tugatish"}
                            </button>
                        </Col>
                    </>
                )
            }
            <Result
                setIsOpenModal={setIsOpenModal}
                isOpenModal={isOpenModal}
                finishCourseLesson={finishCourseLesson}
                resultsInfo={resultsInfo}
                finishLoading={finishLoading}
                quizType="simple"
            />
        </div>
    )
}

export default Quiz