// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.not-found-page .text-container {
  margin: 30px 0;
  text-align: center;
}

.not-found-page .text-container h1 {
  font-size: 40px;
  font-weight: 600;
}

.not-found-page .text-container p {
  font-size: 16px;
  color: #888;
}

@media (max-width: 768px) {
  .not-found-page .svg-wrap {
    transform: scale(0.6);
  }
  .not-found-page .text-container h1 {
    font-size: 28px;
  }
  .not-found-page .text-container p {
    font-size: 14px;
  }
  .not-found-page .text-container {
    margin: 15px 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/not-found/not-found.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE;IACE,qBAAqB;EACvB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":[".not-found-page {\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.not-found-page .text-container {\n  margin: 30px 0;\n  text-align: center;\n}\n\n.not-found-page .text-container h1 {\n  font-size: 40px;\n  font-weight: 600;\n}\n\n.not-found-page .text-container p {\n  font-size: 16px;\n  color: #888;\n}\n\n@media (max-width: 768px) {\n  .not-found-page .svg-wrap {\n    transform: scale(0.6);\n  }\n  .not-found-page .text-container h1 {\n    font-size: 28px;\n  }\n  .not-found-page .text-container p {\n    font-size: 14px;\n  }\n  .not-found-page .text-container {\n    margin: 15px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
