import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { Col, Empty } from 'antd'
import queryString from 'query-string'
import axios from 'axios'
import { BASE_URL } from '../../../constants'
import Cookies from 'js-cookie'
import http from '../../../utils/axiosInterceptors'
import "video.js/dist/video-js.css";
import Loader from '../../../components/loader'
import videojs from "video.js";
import { useNavigate, useParams } from 'react-router-dom'

const VideoCourse = (props) => {
    const { courseSections, getCourseDetails } = props

    const navigate = useNavigate()
    const params = useParams()
    const courseId = params.courseId

    const token = Cookies.get('access_token')
    const { lessonId } = queryString.parse(window.location.search)

    const [lessonDetail, setLessonDetail] = useState({})
    const [youtubeVideoLink, setYoutubeVideoLink] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [player, setPlayer] = useState(false)
    const [activeH5, setActiveH5] = useState("description")

    const getCourseLessonDetails = async () => {
        setIsLoading(true)
        setYoutubeVideoLink("")
        try {
            const response = await http.get(`/cource/lesson/detail/${lessonId}/`)
            setLessonDetail(response?.data)

            if (response?.data?.video?.is_file) {
                try {
                    const videoResponse = await axios.get(`${BASE_URL}cource/content/`, {
                        headers: {
                            "content": btoa(response?.data?.video?.id),
                            Authorization: `Bearer ${token}`,
                        }
                    })
                    const videoBlob = await videoResponse.data.blob();
                    const url = URL.createObjectURL(videoBlob);

                    const videoPlayer = videojs("video-player", {
                        controls: true,
                        preload: "auto",
                        sources: [
                            {
                                src: url,
                                type: "video/mp4",
                            },
                        ],
                    });

                    setPlayer(videoPlayer);

                } catch (error) {
                    console.log(error)
                }
            } else {
                setYoutubeVideoLink(response?.data?.video?.link)
            }

        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const [finishLoading, setFinishLoading] = useState(false)
    const finishCourseLesson = async () => {
        setFinishLoading(true)
        try {
            const response = await http.post(`/cource/finish/`, {
                enrolled_course_id: courseId,
                lesson_id: lessonId,
            });
            const nextId = response?.data?.data?.next_lesson_id;
            if (nextId) {
                courseSections?.forEach(section => {
                    section?.lessons?.forEach(lesson => {
                        if (lesson?.id === nextId) {
                            navigate(`?${queryString.stringify({ lessonType: lesson?.lesson_type, lessonId: lesson?.id, sectionId: section?.id })}`)
                        }
                    })
                })
                getCourseDetails()
            } else {
                navigate(`?${queryString.stringify({ lessonType: "main-quiz", lessonId: undefined, sectionId: undefined })}`)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFinishLoading(false)
        }
    };

    useEffect(() => {
        getCourseLessonDetails()

        // eslint-disable-next-line
    }, [lessonId, player])

    return (
        <div className='video-course-page'>
            {
                isLoading ? <Loader /> : (
                    <>
                        <h1>{lessonDetail?.title}</h1>
                        <div className='video-wrap'>

                            {
                                youtubeVideoLink ? (
                                    <iframe
                                        src={
                                            youtubeVideoLink?.includes(
                                                "youtube-nocookie.com/"
                                            )
                                                ? youtubeVideoLink
                                                : `https://www.youtube-nocookie.com/embed/${youtubeVideoLink?.split("/")[3]
                                                }?autoplay=0&controls=1&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&noCookie=true&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1`
                                        }
                                        title={"Tittle"}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        className="w-100"
                                        style={{ height: "400px" }}
                                    />
                                ) : (
                                    <div data-vjs-player>
                                        <video
                                            id="video-player"
                                            className="video-js vjs-big-play-centered vjs-16-9"
                                            playsInline
                                            preload="auto"
                                        ></video>
                                    </div>
                                )
                            }
                        </div>
                        <div className='tab'>
                            <h5 className={activeH5 === 'description' ? 'active' : ''} onClick={() => setActiveH5('description')}>Dars haqida</h5>
                            <h5 className={activeH5 === 'useful' ? 'active' : ''} onClick={() => setActiveH5('useful')}>Foydali ma'lumotlar</h5>
                        </div>
                        {
                            activeH5 === 'description' ? (
                                <div>
                                    {lessonDetail?.description ? parse(lessonDetail?.description) : null}
                                </div>
                            ) : null
                        }
                        {
                            activeH5 === 'useful' ? (
                                <div className='useful'>
                                    {lessonDetail?.usefull_resources?.length === 0 ? <Empty /> : null}
                                    {
                                        lessonDetail?.usefull_resources?.map((item, index) => {
                                            return (
                                                <a href={item?.file}>
                                                    <li key={index}>{item?.title}</li>
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            ) : null
                        }

                        <Col
                            span={8}
                            style={{ marginLeft: 'auto', marginTop: '20px' }}
                        >
                            <button disabled={finishLoading} className='green-btn' onClick={finishCourseLesson}>
                                {finishLoading ? "Iltimos kuting..." : "Keyingi dars"}
                            </button>
                        </Col>
                    </>
                )
            }
        </div>
    )
}

export default VideoCourse