import React, { useEffect, useState } from 'react'
import './my-courses.css'
import Sidebar from '../../components/sidebar'
import { Empty, Row } from 'antd'
import Course from '../../components/course'
import http from '../../utils/axiosInterceptors'
import Loader from '../../components/loader'
import { useTranslation } from 'react-i18next'

const MyCourses = () => {
    const { t } = useTranslation()

    const [myCourses, setMyCourses] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getMyCourses = async () => {
        setIsLoading(true)
        setMyCourses([])
        try {
            const response = await http.get(`/cource/enroll/`)
            setMyCourses(response?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getMyCourses()
    }, [])

    return (
        <div className='my-courses-page container'>
            <Sidebar />
            <div className='right box'>
                <h1>{t('profil.my-courses')}</h1>
                <Row
                    gutter={[24, 24]}
                >
                    {isLoading ? <Loader /> : null}
                    {myCourses.length === 0 && !isLoading ? <Empty /> : null}
                    {
                        myCourses?.map((course, index) => {
                            return (
                                <Course
                                    key={index}
                                    enrolledId={course?.id}
                                    course={course?.course}
                                    course_progress={course?.course_progress}
                                    xl={12}
                                    lg={24}
                                    md={12}
                                    sm={24}
                                    xs={24}
                                />
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export default MyCourses