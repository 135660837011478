import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './sidebar.css'
import { useDispatch } from 'react-redux'
import { userLogoutSuccess } from '../../reducers/authSlice'
import { UserContext } from '../../App'
import { Image } from 'antd'
import { useTranslation } from 'react-i18next'

const Sidebar = () => {
    const { userData } = useContext(UserContext)
    const location = window.location.pathname
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const userLogout = () => {
        dispatch(userLogoutSuccess())
    }

    return (
        <div className='sidebar-component'>
            <div className='top'>
                <div style={{ width: '200px', height: '200px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', marginBottom: '20px' }}>
                    <Image
                        width={200}
                        src={userData?.picture}
                    />
                </div>
                <h1 style={{ textAlign: 'center' }}>{userData?.user?.first_name} <br />{userData?.user?.last_name}</h1>
                <p>{t('profil.student')}</p>
            </div>
            <div className='bottom'>
                <div className='row-link'>
                    <div className='left-line' style={{ visibility: 'hidden' }}></div>
                    <h1>{t('profil.settings')}</h1>
                </div>
                <Link to='/my-profile'>
                    <div className={location.includes('my-profile') ? 'row-link active' : 'row-link'}>
                        <div className='left-line'></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18.5588 19.5488C17.5654 16.8918 15.0036 15 12 15C8.99638 15 6.4346 16.8918 5.44117 19.5488M18.5588 19.5488C20.6672 17.7154 22 15.0134 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.0134 3.33285 17.7154 5.44117 19.5488M18.5588 19.5488C16.8031 21.0756 14.5095 22 12 22C9.49052 22 7.19694 21.0756 5.44117 19.5488" strokeWidth="1.5" strokeLinejoin="round" />
                            <circle cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 9 12)" strokeWidth="1.5" strokeLinejoin="round" />
                        </svg>
                        <h3>{t('profil.edit-btn')}</h3>
                    </div>
                </Link>
                <Link to='/my-wallet'>
                    <div className={location.includes('my-wallet') ? 'row-link active' : 'row-link'}>
                        <div className='left-line'></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect x="2" y="4" width="20" height="16" rx="4" strokeWidth="1.5" />
                            <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 10 14)" strokeWidth="1.5" />
                            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 17 13)" />
                            <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 5 13)" />
                        </svg>
                        <h3>{t('profil.my-balans')}</h3>
                    </div>
                </Link>
                <Link to='/my-courses'>
                    <div className={location.includes('my-courses') ? 'row-link active' : 'row-link'}>
                        <div className='left-line'></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 6.05337V20.3025M5 8.25464C6.26578 8.45067 7.67778 8.77657 9 9.28788M5 12.2546C5.63949 12.3537 6.3163 12.4859 7 12.6584M3.99433 3.01127C6.21271 3.26195 9.19313 3.93632 11.3168 5.42445C11.725 5.71045 12.275 5.71045 12.6832 5.42445C14.8069 3.93632 17.7873 3.26195 20.0057 3.01127C21.1036 2.88721 22 3.80402 22 4.93518V16.2C22 17.3311 21.1036 18.2483 20.0057 18.3723C17.7873 18.623 14.8069 19.2974 12.6832 20.7855C12.275 21.0715 11.725 21.0715 11.3168 20.7855C9.19313 19.2974 6.21271 18.623 3.99433 18.3723C2.89642 18.2483 2 17.3311 2 16.2V4.93518C2 3.80402 2.89642 2.88721 3.99433 3.01127Z" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        <h3>{t('profil.my-courses')}</h3>
                    </div>
                </Link>
                <Link to='/my-socials'>
                    <div className={location.includes('socials') ? 'row-link active' : 'row-link'}>
                        <div className='left-line'></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect x="2" y="14" width="8" height="8" rx="2" strokeWidth="1.5" />
                            <rect x="2" y="2" width="8" height="8" rx="2" strokeWidth="1.5" />
                            <rect x="14" y="14" width="8" height="8" rx="2" strokeWidth="1.5" />
                            <path d="M13.1007 8.07108C12.3197 7.29003 12.3197 6.0237 13.1007 5.24266L15.9292 2.41423C16.7102 1.63318 17.9766 1.63318 18.7576 2.41423L21.586 5.24266C22.3671 6.0237 22.3671 7.29003 21.586 8.07108L18.7576 10.8995C17.9766 11.6806 16.7102 11.6806 15.9292 10.8995L13.1007 8.07108Z" strokeWidth="1.5" />
                        </svg>
                        <h3>{t('profil.my-socials')}</h3>
                    </div>
                </Link>
                <Link to='/' onClick={userLogout}>
                    <div className={location.includes('logout') ? 'row-link active' : 'row-link'}>
                        <div className='left-line'></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 14L19.2929 12.7071C19.6834 12.3166 19.6834 11.6834 19.2929 11.2929L18 10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M19 12L13 12M4 17.2663V7.26633M16 17.2663C16 18.3709 15.1046 19.2663 14 19.2663H10M16 7.26633C16 6.16176 15.1046 5.26633 14 5.26633H10M4.8906 19.8601L6.8906 21.1934C8.21971 22.0795 10 21.1267 10 19.5293V5.00336C10 3.40597 8.21971 2.45319 6.8906 3.33926L4.8906 4.6726C4.3342 5.04353 4 5.66799 4 6.3367V18.196C4 18.8647 4.3342 19.4891 4.8906 19.8601Z" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        <h3>{t('profil.logout')}</h3>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Sidebar