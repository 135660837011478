import React from 'react'
import './loader.css'

const Loader = () => {
    return (
        <div className='loader-component'>
            <div className="loadingio-spinner-spin-dftkvc0y2q5"><div className="ldio-0f86q94y2f5h">
                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
            </div></div>
        </div>
    )
}

export default Loader