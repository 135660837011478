import React, { useContext, useEffect, useState } from 'react'
import queryString from 'query-string';
import './my-profile.css'
import http from '../../utils/axiosInterceptors';
import { useDispatch, useSelector } from 'react-redux';
import { userLoginSuccess } from '../../reducers/authSlice';
import Loader from '../../components/loader';
import Sidebar from '../../components/sidebar';
import { Button, Col, DatePicker, Form, Input, Row, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Offerta from '../../modals/offerta';
import { useCookieState } from 'ahooks';
import { useTranslation } from 'react-i18next';

const MyProfile = () => {
    const { code } = queryString.parse(window.location.search)
    const { accessToken } = useSelector(state => state.auth)
    const isAuth = !!accessToken

    const { t } = useTranslation()

    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { userData, getProfileData } = useContext(UserContext)

    const [isAcceptedOfferta, setIsAcceptedOfferta] = useCookieState(
        "is_accepted_offerta",
        {
            defaultValue: false,
            expires: 1,
        }
    )

    const [isModalOpen, setIsModalOpen] = useState(true)
    const [isLoading, setIsloading] = useState(false)
    const [allRegions, setAllRegions] = useState([])
    const [regionId, setRegionId] = useState('')
    const [allDistricts, setAllDistricts] = useState([])
    const [updateLoading, setUpdateLoading] = useState(false)

    const getRegions = async () => {
        setAllRegions([])
        try {
            const response = await http.get(`/common/address/`)
            response?.data?.forEach(item => {
                setAllRegions(prev => [...prev, { label: item?.name, value: item?.id }])
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getDistricts = async () => {
        setAllDistricts([])
        try {
            const response = await http.get(`/common/address/?region_id=${regionId}`)
            response?.data?.forEach(item => {
                setAllDistricts(prev => [...prev, { label: item?.name, value: item?.id }])
            })
        } catch (error) {
            console.log(error)
        }
    }

    const submitLogin = async () => {
        setIsloading(true)
        try {
            const response = await http.get(`/account/login-oneid/?code=${code}`)
            dispatch(userLoginSuccess(response.data.data))
        } catch (error) {
            console.log(error)
        } finally {
            setIsloading(false)
        }
    }

    const [fileList, setFileList] = useState([])

    const handleChange = ({ fileList: newFileList }) => {
        setFileList([])
        setFileList(newFileList)
    }

    const updateProfilePhoto = async (f) => {
        const formData = new FormData()
        formData.append('picture', f.file)

        try {
            const response = await http.put(`/student/profile/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            setFileList(
                fileList?.map(item => {
                    return { ...item, status: 'done', thumbUrl: response?.data?.picture, url: response?.data?.picture }
                })
            )
            getProfileData()
            toast.success("Profil rasmingiz muvaffaqqiyatli yangilandi!")
        } catch (error) {
            console.log(error)
        }
    }

    const onFinish = async (values) => {
        const birthDate = dayjs(values.birth_date)?.format('YYYY-MM-DD')
        const data = { ...values, birth_date: birthDate }

        const formData = new FormData()
        formData.append('bio', data.bio)
        formData.append('birth_date', data.birth_date)
        formData.append('region', data.region)
        formData.append('city', data.city)
        formData.append('phone_number', data.phone_number)
        formData.append('email', data.email)
        formData.append('address', data.address)

        setUpdateLoading(true)
        try {
            await http.put(`/student/profile/`, formData)
            toast.success("Profilingiz muvaffaqqiyatli yangilandi!")
        } catch (error) {
            toast.error("Qandaydir xatolik sodir bo'ldi!")
        } finally {
            setUpdateLoading(false)
        }
    }

    useEffect(() => {
        if (code) {
            submitLogin()
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (userData) {
            form.setFieldValue('first_name', userData?.user?.first_name)
            form.setFieldValue('last_name', userData?.user?.last_name)
            form.setFieldValue('bio', userData?.bio)
            form.setFieldValue('address', userData?.address)
            form.setFieldValue('birth_date', dayjs(userData?.birth_date))
            form.setFieldValue('city', userData?.city)
            form.setFieldValue('phone_number', userData?.phone_number)
            form.setFieldValue('region', userData?.region)
            setRegionId(userData?.region)
        }

        // eslint-disable-next-line
    }, [userData])

    useEffect(() => {
        getRegions()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (regionId) {
            getDistricts()
        }
        // eslint-disable-next-line
    }, [regionId])

    return (
        <div className='my-profile-page container'>
            {
                isLoading ? <Loader /> : (
                    <>

                        {false && isAuth && !isAcceptedOfferta ? (
                            <Offerta
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                setIsAcceptedOfferta={setIsAcceptedOfferta}
                            />
                        ) : null}

                        <Sidebar />

                        <Form
                            name="Add or edit task"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                            labelCol={{ span: 24 }}
                            form={form}
                            className='right box'
                        >
                            <h1>{t('profil.profil')}</h1>
                            <p>{t('profil.text')}</p>
                            <hr />

                            <h1>{t('profil.picture')}</h1>
                            <p>{t('profil.pic-text')}</p>
                            <Upload
                                accept={'.png,.jpg,.jpeg'}
                                customRequest={updateProfilePhoto}
                                onChange={handleChange}
                                fileList={fileList}
                                onError={() => toast.error('Error on upload!')}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>{t('profil.file')}</Button>
                            </Upload>

                            <hr />

                            <h1>{t('profil.private')}</h1>
                            <p>{t('profil.edit')}</p>
                            <br />
                            <Row
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={t('profil.firstname')}
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('profil.write'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('profil.firstname')}
                                            size='large'
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={t('profil.lastname')}
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('profil.write'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('profil.lastname')}
                                            size='large'
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={24} xs={24}>
                                    <Form.Item
                                        label={t('profil.bio')}
                                        name="bio"
                                    >
                                        <Input.TextArea
                                            placeholder='BIO'
                                            style={{
                                                height: 120,
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={t('profil.region')}
                                        name="region"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('profil.select'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t('profil.select')}
                                            options={allRegions}
                                            size='large'
                                            value={regionId}
                                            onChange={(e) => setRegionId(e)}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={t('profil.city')}
                                        name="city"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('profil.select'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder={t('profil.select')}
                                            size='large'
                                            options={allDistricts}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={t('profil.phone')}
                                        name="phone_number"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('profil.write'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder='+998'
                                            mask='+\9\9\8999999999'
                                            size='large'
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item
                                        label={t('profil.birth')}
                                        name="birth_date"
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            size='large'
                                            placeholder={t('profil.select')}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col lg={24} xs={24}>
                                    <Form.Item
                                        label={t('profil.address')}
                                        name="address"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('profil.write'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={t('profil.address')}
                                            size='large'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button className='yellow-btn'>
                                        {updateLoading ? t('profil.wait') : t('profil.edit-btn')}
                                    </button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )
            }
        </div>
    )
}

export default MyProfile