import React from 'react'
import './comment.css'
import { Col } from 'antd'

const Comment = () => {
    return (
        <Col>
            <div className='one-comment'>
                <div className='bg'></div>
                <p>Yordamingiz uchun katta rahmat, Bu haqiqatan ham vaqtimni tejaydi. Katta rahmat yordamingiz uchun katta. Bu men qidirgan narsam. </p>
                <span>Boltayev Davronbek</span>
            </div>
        </Col>
    )
}

export default Comment