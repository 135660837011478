import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './instructor-detail.css'
import { Rate, Steps } from 'antd'
import http from '../../utils/axiosInterceptors'
import Loader from '../../components/loader'
import parse from 'html-react-parser'
import Course from '../../components/course'
import { useTranslation } from 'react-i18next'

const InstructorDetail = () => {
    const { t } = useTranslation()

    const { instructorId } = useParams()

    const [instructorDetails, setInstructorDetails] = useState({})
    const [instructorLessons, setInstructorLessons] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [educationItems, setEducationItems] = useState([])
    const [experienceItems, setExperienceItems] = useState([])

    const getInstructorDetail = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`/instructor/${instructorId}/`)
            setInstructorDetails(response?.data)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const getInstructorCourses = async () => {
        try {
            const response = await http.get(`/cource/list/?instructor=${instructorId}`)
            setInstructorLessons(response?.data?.results)
        } catch (err) {
            console.log(err)
        }
    }

    // console.log(instructorDetails)

    useEffect(() => {
        getInstructorDetail()
        getInstructorCourses()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setEducationItems([])
        setExperienceItems([])

        instructorDetails?.instructor_education?.map(item =>
            setEducationItems(prev => [
                ...prev, {
                    title: `${item?.unversity_name} (${item?.start_date} - ${item?.is_active ? 'Hozirgacha' : item?.end_date})`,
                    description: item?.description
                }
            ])
        )

        instructorDetails?.instructor_experience?.map(item =>
            setExperienceItems(prev => [
                ...prev, {
                    title: `${item?.company_name} (${item?.start_date} - ${item?.is_current ? 'Hozirgacha' : item?.end_date})`,
                    description: item?.description
                }
            ])
        )
    }, [instructorDetails])

    return (
        <div className='instructor-detail-page container'>
            <div className='top-navigator'>
                <Link to="/">
                    <div>
                        {t('navbar.home')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <div className='active'>
                    {instructorDetails?.instructor_name}
                </div>
            </div>
            {
                isLoading ? <Loader /> : (
                    <div className='wrapper'>
                        <div className='left'>
                            <div className='top box'>
                                <div className='about-techer'>
                                    <div className='bg' style={{ backgroundImage: `url(${instructorDetails?.image})` }}></div>
                                    <div className='texts-wrap'>
                                        <h1>{instructorDetails?.instructor_name}</h1>
                                        <p>{instructorDetails?.profession}</p>
                                    </div>
                                </div>
                                <h1>{instructorDetails?.is_organization ? instructorDetails?.instructor_name : t('detail-page.teacher')} {t('detail-page.about')}</h1>
                                <div>
                                    {instructorDetails?.description ? parse(instructorDetails?.description) : null}
                                </div>
                            </div>
                            {
                                instructorDetails?.is_organization ? null : (
                                    <div className='top box'>
                                        <h1>{t('detail-page.read')}</h1>
                                        <Steps
                                            progressDot
                                            current={10}
                                            direction="vertical"
                                            items={educationItems}
                                        />
                                    </div>
                                )
                            }
                            <div className='top box'>
                                <h1>{instructorDetails?.is_organization ? `${instructorDetails?.instructor_name} ${t('detail-page.experience')}` : t('detail-page.experience')}</h1>
                                <Steps
                                    progressDot
                                    current={10}
                                    direction="vertical"
                                    items={experienceItems}
                                />
                            </div>

                            <div className='box'>
                                <h1>{t('detail-page.courses')}</h1>
                                {
                                    instructorLessons?.map((item, index) => {
                                        return (
                                            <Course
                                                course={item}
                                                key={index}
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={24}
                                                xs={24}
                                            />
                                        )
                                    })
                                }
                            </div>

                        </div>
                        <div className='right'>
                            <div className='top box'>
                                <h1>{t('detail-page.profil')}</h1>
                                {
                                    instructorDetails?.total_rating_avg > 0 ? (
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                            <Rate
                                                value={instructorDetails?.total_rating_avg}
                                                disabled
                                            />
                                            <p style={{ margin: '0', marginLeft: '15px' }}>{instructorDetails?.total_rating_avg}</p>
                                        </div>
                                    ) : null
                                }
                                <div className='line'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 6.03558V15.535M5 8C3.34315 8 2 9.34315 2 11V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V11C22 9.34315 20.6569 8 19 8M6.39603 4.00751C7.94889 4.17463 10.0352 4.62421 11.5218 5.6163C11.8075 5.80697 12.1925 5.80697 12.4782 5.6163C13.9648 4.62421 16.0511 4.17463 17.604 4.00751C18.3725 3.92481 19 4.53602 19 5.29012V12.8C19 13.5541 18.3725 14.1655 17.604 14.2482C16.0511 14.4153 13.9648 14.8649 12.4782 15.857C12.1925 16.0477 11.8075 16.0477 11.5218 15.857C10.0352 14.8649 7.94889 14.4153 6.39603 14.2482C5.6275 14.1655 5 13.5541 5 12.8V5.29012C5 4.53602 5.6275 3.92481 6.39603 4.00751Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p>{instructorDetails?.cources_count} {t('detail-page.course')}</p>
                                </div>
                                <div className='line'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
                                        <path d="M14.439 9.82804V8.78439L15.649 9.32855C16.179 9.5669 16.7865 9.56293 17.3134 9.31767L18.5586 8.738V9.82804C18.5586 10.9656 17.6364 11.8878 16.4988 11.8878C15.3612 11.8879 14.439 10.9656 14.439 9.82804Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" />
                                        <path d="M20.5214 7.41079L17.1 5.89015C16.707 5.71549 16.2584 5.71549 15.8655 5.89015L12.444 7.41079C12.2434 7.49993 12.2434 7.7846 12.444 7.87374L15.8655 9.39439C16.2584 9.56905 16.707 9.56905 17.1 9.39439L20.5214 7.87374C20.722 7.7846 20.722 7.49993 20.5214 7.41079Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.4704 13.4525C14.5734 13.4162 14.6791 13.3826 14.7872 13.3519C14.9625 13.302 15.1501 13.3556 15.2786 13.4813L16.1104 14.2951C16.3154 14.4956 16.6501 14.4956 16.8551 14.2951L17.6869 13.4813C17.8154 13.3556 18.003 13.302 18.1783 13.3519C19.6472 13.7698 20.6719 14.7141 20.6719 15.812C20.6719 16.3715 20.203 16.8252 19.6246 16.8252H15.6627" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <line x1="12.6445" y1="8.39227" x2="12.6445" y2="10.0586" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" />
                                        <path d="M4.49874 7.83826V6.20217L6.77067 7.04887C7.22116 7.21675 7.71704 7.21675 8.16753 7.04887L10.4395 6.20217V7.83826C10.4395 9.47874 9.10958 10.8086 7.4691 10.8086C5.82862 10.8086 4.49874 9.47875 4.49874 7.83826Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" />
                                        <path d="M13.3889 4.07452L8.37377 1.84558C7.79775 1.58957 7.14023 1.58957 6.56421 1.84558L1.5491 4.07452C1.2551 4.20518 1.2551 4.62244 1.5491 4.7531L6.56421 6.98204C7.14023 7.23805 7.79775 7.23805 8.37377 6.98204L13.3889 4.7531C13.6829 4.62244 13.6829 4.20518 13.3889 4.07452Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.70397 12.991L6.92324 14.1838C7.22366 14.4777 7.71432 14.4777 8.01474 14.1838L9.23401 12.991C9.42236 12.8067 9.69733 12.7282 9.95431 12.8013C12.1074 13.4139 13.6094 14.7979 13.6094 16.4072C13.6094 17.2275 12.9221 17.8924 12.0743 17.8924H2.8637C2.01589 17.8924 1.3286 17.2275 1.3286 16.4072C1.3286 14.7979 2.83059 13.4139 4.98366 12.8013C5.24065 12.7282 5.51562 12.8067 5.70397 12.991Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <line x1="2.20117" y1="5.2812" x2="2.20117" y2="8.30502" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                    <p>{instructorDetails?.students_count} {t('detail-page.students')}</p>
                                </div>
                                <div className='line'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M8 10H16M8 14H12M11 3H13C17.9706 3 22 7.02944 22 12C22 16.9706 17.9706 21 13 21H6C3.79086 21 2 19.2091 2 17V12C2 7.02944 6.02944 3 11 3Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                    <p>{instructorDetails?.total_rating_count} {t('detail-page.comments')}</p>
                                </div>
                            </div>
                            <div className='bottom box'>
                                <h1>{t('detail-page.contact')}</h1>
                                <div className='line'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z" stroke="#0C0C0C" strokeWidth="1.5" />
                                        <path d="M21 10.8889C21 15.7981 15.375 22 12 22C8.625 22 3 15.7981 3 10.8889C3 5.97969 7.02944 2 12 2C16.9706 2 21 5.97969 21 10.8889Z" stroke="#0C0C0C" strokeWidth="1.5" />
                                    </svg>
                                    <p>{instructorDetails?.region} {instructorDetails?.city} {instructorDetails?.address}</p>
                                </div>
                                {
                                    instructorDetails?.email ? (
                                        <div className='line'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect x="2" y="3" width="20" height="18" rx="4" stroke="#0C0C0C" strokeWidth="1.5" />
                                                <path d="M2 7L9.50122 13.001C10.9621 14.1697 13.0379 14.1697 14.4988 13.001L22 7" stroke="#0C0C0C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <p>{instructorDetails?.email}</p>
                                        </div>
                                    ) : null
                                }
                                {
                                    instructorDetails?.phone ? (
                                        <div className='line'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M21 19V17.3541C21 16.5363 20.5021 15.8008 19.7428 15.4971L17.7086 14.6835C16.7429 14.2971 15.6422 14.7156 15.177 15.646L15 16C15 16 12.5 15.5 10.5 13.5C8.5 11.5 8 9 8 9L8.35402 8.82299C9.28438 8.35781 9.70285 7.25714 9.31654 6.29136L8.50289 4.25722C8.19916 3.4979 7.46374 3 6.64593 3H5C3.89543 3 3 3.89543 3 5C3 13.8366 10.1634 21 19 21C20.1046 21 21 20.1046 21 19Z" stroke="#0C0C0C" strokeWidth="1.5" strokeLinejoin="round" />
                                            </svg>
                                            <p>{instructorDetails?.phone}</p>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default InstructorDetail