import React from 'react'
import './course-sidebar.css'
import { Collapse, Empty } from 'antd'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { toast } from 'react-toastify'

const panelStyle = {
    marginBottom: 16,
    background: "#F8F8F8",
    borderRadius: "8px",
    border: 'none',
    color: "#0C0C0C",
    fontSize: "16px",
    fontWeight: "500"
};

const CourseSidebar = (props) => {
    const { courseSections, courseProgress, isCertificatCreated, certificateFile } = props

    const { sectionId, lessonId } = queryString.parse(window.location.search)

    const navigate = useNavigate()

    const startMainTest = async () => {
        if (courseProgress === 100) {
            navigate(`?${queryString.stringify({ lessonType: "main-quiz", lessonId: undefined, sectionId: undefined })}`)
        } else {
            toast.info("Yakuniy imtihonni boshlashingiz uchun barcha kurslarni tugatgan bo'lishingiz kerak!")
        }
    }

    const changeView = (lessonType, lessonId, sectionId, isSectionOpened) => {
        if (isSectionOpened) {
            navigate(`?${queryString.stringify({ lessonType, lessonId, sectionId })}`)
        } else {
            toast.info("Darsni ko'rish uchun oldingi bo'limdagi barcha kurslarni yakunlagan bo'lishingiz kerak!")
        }
    }

    const courseSvgType = (t) => {
        switch (t) {
            case 'video':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.9611 13.7365L8.99228 18.2901C7.65896 19.052 6 18.0893 6 16.5537V7.44638C6 5.91073 7.65896 4.948 8.99228 5.70989L16.9611 10.2635C18.3048 11.0313 18.3048 12.9687 16.9611 13.7365Z" strokeWidth="1.5" strokeLinejoin="round" />
                    </svg>
                )
            case 'quiz':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                        <path d="M16 4H17C19.2091 4 21 5.79086 21 8V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V8C3 5.79086 4.79086 4 7 4H8M16 4C16 5.10457 15.1046 6 14 6H10C8.89543 6 8 5.10457 8 4M16 4C16 2.89543 15.1046 2 14 2H10C8.89543 2 8 2.89543 8 4M8 10H16M8 14H16M8 18H12" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )
            case 'assignment':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M8 18H16M8 18C8 20.2091 9.79086 22 12 22C14.2091 22 16 20.2091 16 18M8 18V15.7887C8 15.1349 7.66659 14.5363 7.19153 14.0871C5.84201 12.8111 5 11.0039 5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9C19 11.0039 18.158 12.8111 16.8085 14.0871C16.3334 14.5363 16 15.1349 16 15.7887V18M10 9L12 11M12 11L14 9M12 11V18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                )
            default:
                return null
        }
    }

    const getItems = (panelStyle) => Object.values(courseSections)?.map((item, index) => {
        return {
            key: item?.id,
            label: <div className='section-label'>
                <p>{item?.name}</p>
                <span>{index + 1}-dars</span>
            </div>,
            children: [
                item?.lessons?.map((lesson) => {
                    return (
                        <div key={lesson?.id}>
                            <div className={Number(lessonId) === lesson?.id ? 'one-row active' : 'one-row'} onClick={() => changeView(lesson?.lesson_type, lesson?.id, item?.id, item?.is_section_opened)}>
                                <div>
                                    {
                                        item?.is_section_opened ? (
                                            courseSvgType(lesson?.lesson_type)
                                        ) : (
                                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13 7H5M13 7C15.2091 7 17 8.79086 17 11V17C17 19.2091 15.2091 21 13 21H5C2.79086 21 1 19.2091 1 17V11C1 8.79086 2.79086 7 5 7M13 7V5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5V7M11 14C11 15.1046 10.1046 16 9 16C7.89543 16 7 15.1046 7 14C7 12.8954 7.89543 12 9 12C10.1046 12 11 12.8954 11 14Z" stroke="#28303F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        )
                                    }
                                    <p>{lesson?.title}</p>
                                </div>
                                <input type='checkbox' disabled checked={lesson?.is_finished} />
                            </div>
                        </div>
                    )
                }),
                <div key={item?.id} className='progress'>
                    <div style={{ width: `${item?.progress_percentage}%` }}></div>
                </div>
            ],
            style: panelStyle,
        }
    })

    return (
        <div className='course-sidebar-component box'>
            <h1>Kurs mavzulari</h1>
            {courseSections?.length === 0 ? <Empty /> : null}
            <Collapse
                expandIconPosition='end'
                bordered={false}
                defaultActiveKey={[sectionId ? sectionId : (courseSections ? courseSections[0]?.id : '0')]}
                style={{
                    background: "transparent",
                }}
                items={getItems(panelStyle)}
            />

            <div className='sidebar-btn' onClick={startMainTest}>
                <div className='curtain' style={{ width: `${courseProgress}%` }}></div>
                <span>Yakuniy imtihon</span>
            </div>
            {
                isCertificatCreated ? (
                    <a href={certificateFile} target='_blank' rel="noreferrer">
                        <div className='sidebar-btn'>
                            <div className='curtain'></div>
                            <span>Sertifikatni yuklash</span>
                        </div>
                    </a>
                ) : null
            }
        </div>
    )
}

export default CourseSidebar