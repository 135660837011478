import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "../constants";

const token = Cookies.get("access_token");

export function writeHeaders(tokenData) {
  http.defaults.headers.common['Authorization'] = `Bearer ${tokenData}`;
}

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use((config) => {
  config.headers = {
    Authorization: !!token ? `Bearer ${token}` : null,
  };
  return config;
});

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      Cookies.remove('access_token')
      Cookies.remove('refresh_token')
    }
    return Promise.reject(error);
  }
);

export default http;
