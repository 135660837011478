import React, { useEffect, useState } from 'react'
import './news-detail.css'
import { Link, useParams } from 'react-router-dom'
import { Empty, Row } from 'antd'
import News from '../../components/news'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper/modules';
import http from '../../utils/axiosInterceptors'
import parse from 'html-react-parser'
import Loader from '../../components/loader'

const NewsDetail = () => {
    const params = useParams()
    const id = params.id

    const [isLoading, setIsLoading] = useState(false)
    const [newsDetail, setNewsDetail] = useState()

    const [isOtherNewsLoading, setOtherNewsIsLoading] = useState(false)
    const [otherNews, setOtherNews] = useState([])

    const getNewsDetail = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`/news/detail/${id}`)
            setNewsDetail(response?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getOtherNews = async () => {
        setOtherNewsIsLoading(true)
        setIsLoading(true)
        try {
            const response = await http.get(`/news/list/?page=${1}&page_size=${6}`)
            setOtherNews(response?.data?.results)
        } catch (error) {
            console.log(error)
        } finally {
            setOtherNewsIsLoading(false)
        }
    }

    useEffect(() => {
        getNewsDetail()
        getOtherNews()

        // eslint-disable-next-line
    }, [id])

    return (
        <div className='news-detail-page container'>
            <div className='top-navigator'>
                <Link to="/">
                    <div>
                        Bosh sahifa
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <Link to="/all-news">
                    <div>
                        Barcha yangiliklar
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <div className='active'>
                    {newsDetail?.title}
                </div>
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <div className='news-content box'>
                            <div className='bg-wrap'>
                                <div className='bg' style={{ backgroundImage: `url(${newsDetail?.preview})` }}></div>
                            </div>
                            <div className='top'>
                                <h1>{newsDetail?.title}</h1>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M3 9V18C3 20.2091 4.79086 22 7 22H17C19.2091 22 21 20.2091 21 18V9M3 9V7.5C3 5.29086 4.79086 3.5 7 3.5H17C19.2091 3.5 21 5.29086 21 7.5V9M3 9H21M16 2V5M8 2V5" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                    <span>{newsDetail?.created_at}</span>
                                </div>
                            </div>
                            <div>{newsDetail?.content ? parse(newsDetail?.content) : null}</div>
                        </div>

                        <section>
                            <h3>Boshqa yangiliklar</h3>
                            {!isOtherNewsLoading && otherNews?.length === 0 ? <Empty /> : null}
                            {
                                isOtherNewsLoading ? <Loader /> : (
                                    <Row
                                        gutter={[24, 24]}
                                    >
                                        <Swiper
                                            breakpoints={{
                                                768: {
                                                    slidesPerView: 2,
                                                },
                                                1600: {
                                                    slidesPerView: 3,
                                                },
                                            }}
                                            freeMode={true}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[FreeMode, Pagination]}
                                        >
                                            {
                                                otherNews?.map((item, index) => {
                                                    return (
                                                        <SwiperSlide
                                                            key={index}
                                                        >
                                                            <News
                                                                newsInfo={item}
                                                                xl={24}
                                                                lg={24}
                                                                md={24}
                                                                sm={24}
                                                                xs={24}
                                                            />
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </Row>
                                )
                            }
                        </section>
                    </>
                )
            }
        </div >
    )
}

export default NewsDetail