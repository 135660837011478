import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './all-news.css'
import { Empty, Row } from 'antd'
import News from '../../components/news'
import { Pagination } from 'antd';
import http from '../../utils/axiosInterceptors'
import Loader from '../../components/loader'
import { useTranslation } from 'react-i18next'

const AllNews = () => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [allNews, setAllNews] = useState([])
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const getAllNews = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`/news/list/?page=${currentPage}&page_size=${pageSize}`)
            setCount(response?.data?.count)
            setAllNews(response?.data?.results)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getAllNews()

        // eslint-disable-next-line
    }, [currentPage, pageSize])

    return (
        <div className='all-news-page container'>
            <div className='top-navigator'>
                <Link to="/">
                    <div>
                        {t('navbar.home')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <div className='active'>
                    {t('navbar.news')}
                </div>
            </div>

            <h1 className='header1'>{t('navbar.news')}</h1>
            {
                allNews?.length === 0 && !isLoading ? <Empty /> : null
            }
            {
                isLoading ? <Loader /> : (
                    <>
                        <Row
                            gutter={[24, 24]}
                        >
                            {
                                allNews?.map((item, index) => {
                                    return <News
                                        key={index}
                                        newsInfo={item}
                                        xl={8}
                                        lg={12}
                                        md={12}
                                        sm={24}
                                        xs={24}
                                    />
                                })
                            }
                        </Row>
                        {
                            allNews?.length > 0 ? (
                                <Pagination
                                    total={count}
                                    pageSize={pageSize}
                                    current={currentPage}
                                    onChange={(a, b) => {
                                        setCurrentPage(a)
                                        setPageSize(b)
                                    }}
                                />
                            ) : null
                        }
                    </>
                )
            }
        </div>
    )
}

export default AllNews