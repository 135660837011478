export const defaultTheme = {
    components: {
        Button: {
            colorPrimary: '#42ab43',
            algorithm: true,
        },
        Input: {
            colorPrimary: '#42ab43',
            algorithm: true,
        },
        Select: {
            colorPrimary: '#42ab43',
            algorithm: true,
        },
        Spin: {
            colorPrimary: '#42ab43',
            algorithm: true,
        },
        Radio: {
            colorPrimary: '#42ab43',
            algorithm: true,
        },
    },
}