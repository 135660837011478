import React, { createContext, useEffect, useState } from 'react'
import './App.css'
import Navbar from './components/navbar'
import Footer from './components/footer'
import { useRoutes } from 'react-router-dom'
import { loginRouters, userRouters } from './routers'
import { useSelector } from 'react-redux'
import http from './utils/axiosInterceptors'
import { ConfigProvider } from 'antd'
import { defaultTheme } from './ant-theme'

export const UserContext = createContext()

const App = () => {
  const loginRouting = useRoutes(loginRouters)
  const userRouting = useRoutes(userRouters)

  const { accessToken } = useSelector(state => state.auth)
  const isAuth = !!accessToken

  const [userData, setUserData] = useState({})
  const getProfileData = async () => {
    try {
      const { data } = await http.get('/student/profile/')
      setUserData(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isAuth) {
      getProfileData()
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ConfigProvider
        theme={defaultTheme}
      >
        <UserContext.Provider value={{ userData, getProfileData }}>
          <Navbar />
          {
            isAuth ? userRouting : loginRouting
          }
          <Footer />
        </UserContext.Provider>
      </ConfigProvider>
    </>
  )
}

export default App