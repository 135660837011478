// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-component {
  background-color: #ffffff;
  border-radius: 64px 64px 0 0;
}

.footer-inner {
  padding: 60px 0;
  display: grid;
  grid-template-columns: calc(20% - 20px) calc(20% - 20px) calc(30% - 20px) calc(
      30% - 20px
    );
  gap: 20px;
  justify-content: space-between;
}

.footer-component .one h6 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.footer-component .one svg {
  margin-right: 10px;
}

.footer-component .one p {
  width: calc(100% - 34px);
  color: #888;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.footer-component .one > div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footer-bottom {
  text-align: center;
}

.footer-bottom p {
  color: #888;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 992px) {
  .footer-component {
    border-radius: 32px 32px 0 0;
  }
  .footer-inner {
    padding: 30px;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .footer-component {
    border-radius: 24px 24px 0 0;
  }
  .footer-inner {
    padding: 20px;
    grid-template-columns: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,aAAa;EACb;;KAEG;EACH,SAAS;EACT,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,aAAa;IACb,wDAAwD;EAC1D;AACF;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,aAAa;IACb,2BAA2B;EAC7B;AACF","sourcesContent":[".footer-component {\n  background-color: #ffffff;\n  border-radius: 64px 64px 0 0;\n}\n\n.footer-inner {\n  padding: 60px 0;\n  display: grid;\n  grid-template-columns: calc(20% - 20px) calc(20% - 20px) calc(30% - 20px) calc(\n      30% - 20px\n    );\n  gap: 20px;\n  justify-content: space-between;\n}\n\n.footer-component .one h6 {\n  color: #000;\n  font-size: 20px;\n  font-weight: 600;\n  margin-bottom: 30px;\n}\n\n.footer-component .one svg {\n  margin-right: 10px;\n}\n\n.footer-component .one p {\n  width: calc(100% - 34px);\n  color: #888;\n  font-size: 16px;\n  font-weight: 400;\n  margin: 0;\n}\n\n.footer-component .one > div {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.footer-bottom {\n  text-align: center;\n}\n\n.footer-bottom p {\n  color: #888;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n@media (max-width: 992px) {\n  .footer-component {\n    border-radius: 32px 32px 0 0;\n  }\n  .footer-inner {\n    padding: 30px;\n    grid-template-columns: calc(50% - 20px) calc(50% - 20px);\n  }\n}\n\n@media (max-width: 576px) {\n  .footer-component {\n    border-radius: 24px 24px 0 0;\n  }\n  .footer-inner {\n    padding: 20px;\n    grid-template-columns: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
