import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie"
import { writeHeaders } from "../utils/axiosInterceptors";

const accessToken = Cookies.get('access_token')
const refreshToken = Cookies.get('refresh_token')

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isLoading: false,
        accessToken,
        refreshToken,
        error: null,
    },
    reducers: {
        userLoginStart: (state) => {
            state.isLoading = true
        },
        userLoginSuccess: (state, action) => {
            state.isLoading = false
            state.accessToken = action.payload.access
            state.refreshToken = action.payload.refresh

            Cookies.set('access_token', action.payload.access, { expires: 1 })
            Cookies.set('refresh_token', action.payload.refresh, { expires: 1 })
            writeHeaders(action.payload.access)
            window.location.search = ""
        },
        userLoginFailure: (state) => {
            state.isLoading = false
            state.error = true
        },
        userLogoutSuccess: (state) => {
            Cookies.remove('access_token')
            Cookies.remove('refresh_token')
            Cookies.remove('is_accepted_offerta')
            state.accessToken = null
            state.refreshToken = null
            window.location.reload()
            window.location = "/"
        }
    }
})

export const {
    userLoginStart,
    userLoginSuccess,
    userLoginFailure,
    userLogoutSuccess
} = authSlice.actions

export default authSlice.reducer