import React from 'react'
import logo from '../../assets/images/logo.png'
import './footer.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import VisitorsStatistics from '../visitors'

const Footer = () => {
    const { t } = useTranslation()

    const { accessToken } = useSelector(state => state.auth)
    const isAuth = !!accessToken

    return (
        <div className='footer-component container'>
            <div className='footer-inner'>
                <Link to="/">
                    <img src={logo} alt='' className='logo' />
                    <br />
                    <VisitorsStatistics />
                </Link>
                {
                    isAuth ? (
                        <div className='one'>
                            <h6>{t('footer.pupil')}</h6>
                            <div>
                                <Link to='/my-profile' style={{ whiteSpace: 'nowrap' }}>
                                    <p>{t('footer.my-profile')}</p>
                                </Link>
                            </div>
                            <div>
                                <Link to='/my-courses' style={{ whiteSpace: 'nowrap' }}>
                                    <p>{t('footer.my-courses')}</p>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className='one'>
                            <h6>EDU.KARANTIN.UZ</h6>
                            <div>
                                <Link to='/all-courses' style={{ whiteSpace: 'nowrap' }}>
                                    <p>{t('navbar.all-courses')}</p>
                                </Link>
                            </div>
                            <div>
                                <Link to='/all-news' style={{ whiteSpace: 'nowrap' }}>
                                    <p>{t('navbar.news')}</p>
                                </Link>
                            </div>
                        </div>
                    )
                }
                <div className='one'>
                    <h6>{t('footer.contact')}</h6>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z" stroke="#888888" strokeWidth="1.5" />
                            <path d="M21 10.8889C21 15.7981 15.375 22 12 22C8.625 22 3 15.7981 3 10.8889C3 5.97969 7.02944 2 12 2C16.9706 2 21 5.97969 21 10.8889Z" stroke="#888888" strokeWidth="1.5" />
                        </svg>
                        <p>{t('footer.address')}</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect x="2" y="3" width="20" height="18" rx="4" stroke="#888888" strokeWidth="1.5" />
                            <path d="M2 7L9.50122 13.001C10.9621 14.1697 13.0379 14.1697 14.4988 13.001L22 7" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p>ilmiymarkaz@karantin.uz</p>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21 19V17.3541C21 16.5363 20.5021 15.8008 19.7428 15.4971L17.7086 14.6835C16.7429 14.2971 15.6422 14.7156 15.177 15.646L15 16C15 16 12.5 15.5 10.5 13.5C8.5 11.5 8 9 8 9L8.35402 8.82299C9.28438 8.35781 9.70285 7.25714 9.31654 6.29136L8.50289 4.25722C8.19916 3.4979 7.46374 3 6.64593 3H5C3.89543 3 3 3.89543 3 5C3 13.8366 10.1634 21 19 21C20.1046 21 21 20.1046 21 19Z" stroke="#888888" strokeWidth="1.5" strokeLinejoin="round" />
                        </svg>
                        <p>+99871 203-92-92</p>
                    </div>
                </div>
                <div className='one'>
                    <h6>{t('footer.socials')}</h6>
                    <div style={{ cursor: 'pointer' }}>
                        <a href='https://t.me/Edukarantin' target='_blank' rel="noreferrer" style={{ display: 'flex' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                <path d="M1.90891 8.0952L17.2976 1.44458C18.0212 1.13183 18.8054 1.74041 18.682 2.51906L16.5779 15.795C16.448 16.6151 15.4331 16.9287 14.8631 16.325L11.5295 12.7699C10.8523 12.0526 10.7992 10.9488 11.4045 10.1698L13.7232 6.92966C13.8626 6.75029 13.6425 6.51431 13.4538 6.64086L8.60651 9.89269C7.78393 10.4445 6.78586 10.6715 5.80554 10.5297L2.16248 10.0028C1.16449 9.8585 0.98328 8.49523 1.90891 8.0952Z" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>{t('footer.tg')}</p>
                        </a>
                    </div>
                    <div style={{ cursor: 'pointer' }}>
                        <a href='https://www.instagram.com/edukarantin' target='_blank' rel="noreferrer" style={{ display: 'flex' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="2" y="2" width="20" height="20" rx="4" stroke="#888888" strokeWidth="1.5" />
                                <circle cx="18" cy="6" r="1" fill="#888888" />
                                <circle cx="12" cy="12" r="5" stroke="#888888" strokeWidth="1.5" />
                            </svg>
                            <p>{t('footer.ins')}</p>
                        </a>
                    </div>
                    <div style={{ cursor: 'pointer' }}>
                        <a href='https://www.facebook.com/edukarantin' target='_blank' rel="noreferrer" style={{ display: 'flex' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M18 3H15C12.2386 3 10 5.23858 10 8V10H6V14H10V21H14V14H18V10H14V8C14 7.44772 14.4477 7 15 7H18V3Z" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p>{t('footer.fac')}</p>
                        </a>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
                <p>{t('footer.bottom')}</p>
            </div>
        </div>
    )
}

export default Footer