import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../../utils/axiosInterceptors'
import parse from 'html-react-parser'
import { Col } from 'antd'
import Loader from '../../../components/loader'

const Assigments = (props) => {
    const { courseSections, getCourseDetails } = props

    const params = useParams()
    const courseId = params.courseId
    const navigate = useNavigate()
    const { lessonId } = queryString.parse(window.location.search)

    const [lessonDetail, setLessonDetail] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const getCourseLessonDetails = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`/cource/lesson/detail/${lessonId}/`)
            setLessonDetail(response?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const [finishLoading, setFinishLoading] = useState(false)
    const finishCourseLesson = async () => {
        setFinishLoading(true)
        try {
            const response = await http.post(`/cource/finish/`, {
                enrolled_course_id: courseId,
                lesson_id: lessonId,
            });
            const nextId = response?.data?.data?.next_lesson_id;
            if (nextId) {
                courseSections?.forEach(section => {
                    section?.lessons?.forEach(lesson => {
                        if (lesson?.id === nextId) {
                            navigate(`?${queryString.stringify({ lessonType: lesson?.lesson_type, lessonId: lesson?.id, sectionId: section?.id })}`)
                        }
                    })
                })
                getCourseDetails()
            } else {
                navigate(`?${queryString.stringify({ lessonType: "main-quiz", lessonId: undefined, sectionId: undefined })}`)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFinishLoading(false)
        }
    };

    useEffect(() => {
        getCourseLessonDetails()

        // eslint-disable-next-line
    }, [])

    return (
        <div>
            {
                isLoading ? <Loader /> : (
                    <>
                        <h1>{lessonDetail?.title}</h1>
                        <div>
                            {lessonDetail?.description ? parse(lessonDetail?.description) : null}
                        </div>
                        <div className='useful'>
                            {
                                lessonDetail?.usefull_resources?.map((item, index) => {
                                    return (
                                        <a href={item?.file} target='_blank' rel="noreferrer" download={true}>
                                            <li key={index}>{item?.title}</li>
                                        </a>
                                    )
                                })
                            }
                        </div>
                        <Col
                            span={8}
                            style={{ marginLeft: 'auto' }}
                        >
                            <button disabled={finishLoading} className='green-btn' onClick={finishCourseLesson}>
                                {finishLoading ? "Iltimos kuting..." : "Keyingi dars"}
                            </button>
                        </Col>
                    </>
                )
            }
        </div>
    )
}

export default Assigments