// import { Col } from 'antd'
import React from 'react'
import parse from 'html-react-parser'
// import queryString from 'query-string'
// import { useNavigate } from 'react-router-dom'

const Main = (props) => {
    const { courseDetail } = props
    // const navigate = useNavigate()

    // const firstLessonType = courseSections[0] ? courseSections[0].lessons[0].lesson_type : null
    // const firstLessonId = courseSections[0] ? courseSections[0].lessons[0].id : null
    // const firstSectionId = courseSections[0] ? courseSections[0].id : null

    // const startCourse = () => {
    //     navigate(`?${queryString.stringify({ lessonType: firstLessonType, lessonId: firstLessonId, sectionId: firstSectionId })}`)
    // }

    return (
        <div className='main-course'>
            <h1>{courseDetail?.course_title}</h1>
            <div className='bg-wrap'>
                <div className='bg' style={{ backgroundImage: `url(${courseDetail?.course_image})` }}></div>
            </div>
            <div>
                {courseDetail?.course_description ? parse(courseDetail?.course_description) : null}
            </div>
            {/* <Col
                span={8}
                style={{ marginLeft: 'auto', marginTop: '20px' }}
            >
                <button className='green-btn' onClick={startCourse}>Kursni boshlash</button>
            </Col> */}
        </div>
    )
}

export default Main