export const notFoundSvg = (
    <svg width="536" height="285" viewBox="0 0 536 285" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M414.305 273.009C410.468 277.928 406.016 281.771 406.937 283.462C406.937 283.462 408.932 285 414.612 285C424.283 285 433.8 284.231 432.265 283.309C430.116 281.925 423.822 281.464 417.836 279.312C416.301 278.851 421.059 275.93 419.831 275.469C417.068 274.393 415.073 273.624 414.305 273.009Z" fill="#002E4F" />
        <path d="M513.932 283.155C513.932 283.155 515.16 285 520.072 285C528.515 285 537.111 283.924 535.883 283.002C534.041 281.772 519.304 278.697 519.304 278.697L516.081 274.547L510.555 277.621L513.932 283.155Z" fill="#002E4F" />
        <path d="M487.526 159.717C481.539 159.717 476.934 158.487 476.627 158.487C473.557 157.565 471.715 154.337 472.483 151.262C473.404 148.188 476.627 146.343 479.697 147.112C479.851 147.112 493.052 150.494 500.881 143.884C506.1 139.426 507.942 131.125 506.714 118.981L506.253 115.138L518.073 108.374C520.836 106.837 524.52 107.759 526.055 110.526C527.59 113.293 526.669 116.982 523.906 118.52L518.687 121.44C519.455 135.583 516.078 146.036 508.402 152.646C501.955 158.333 493.82 159.717 487.526 159.717Z" fill="#FFDDAA" />
        <path d="M507.795 126.974C507.642 135.582 505.339 141.731 501.041 145.421C492.598 152.645 478.169 148.956 477.862 148.956C474.485 148.034 470.954 150.032 470.033 153.568C469.112 156.95 471.108 160.485 474.485 161.561C474.945 161.715 479.857 164.175 486.458 163.867C511.479 162.945 526.522 141.424 526.829 128.819L507.795 126.974Z" fill="#E1F3F6" />
        <path d="M473.422 96.9989C471.12 95.9229 468.971 94.8468 466.822 93.7708C446.099 105.607 419.696 120.672 419.082 121.287C419.082 121.287 432.283 142.193 433.051 143.115C433.665 143.73 466.208 120.211 489.694 103.301C484.321 101.149 478.795 99.4584 473.422 96.9989Z" fill="#027803" />
        <path d="M473.704 96.3837C479.076 98.8432 484.756 100.534 490.282 102.686C503.176 93.3093 513.154 86.0844 513.154 86.0844L491.05 79.9355C491.05 79.9355 480.458 85.9307 467.41 93.463C469.559 94.539 471.708 95.4614 473.704 96.3837Z" fill="#42AB43" />
        <path d="M435.646 68.8676L448.694 104.223L474.176 113.447L441.94 54.5715L435.646 68.8676Z" fill="#005E01" />
        <path d="M475.539 122.209H456.658L473.39 151.416L499.025 162.176L475.539 122.209Z" fill="#005D01" />
        <path d="M499.041 162.175L483.998 136.504L419.833 174.165L407.246 152.645L415.228 24.5951L444.087 7.68579L495.971 96.229L513.471 85.9297L528.053 110.679L510.554 120.978L525.597 146.649L499.041 162.175ZM440.863 62.8716L439.175 63.7939L434.723 132.2L469.415 111.755L440.863 62.8716Z" fill="#008601" />
        <path d="M385.906 146.958L407.243 152.8L419.831 174.321L397.266 168.633L385.906 146.958Z" fill="#027803" />
        <path d="M385.906 146.958L407.243 152.799L415.225 24.7495L395.577 19.9841L385.906 146.958Z" fill="#42AB43" />
        <path d="M395.572 19.9844L415.221 24.7497L443.926 7.84042L424.124 1.99902L395.572 19.9844Z" fill="#4FC250" />
        <path d="M438.56 197.994C438.56 197.994 441.63 231.812 441.63 243.649C441.63 243.649 417.07 262.864 410.93 272.856C413.539 274.854 418.605 277.467 421.982 277.314C438.407 261.634 456.367 250.412 456.367 250.412C456.367 250.412 464.195 227.815 466.498 208.754L438.56 197.994Z" fill="#002E4F" />
        <path d="M456.057 199.53C461.583 199.53 467.263 198.608 472.021 195.995C470.486 189.231 473.863 169.247 478.162 158.641C478.162 158.641 481.539 151.262 479.236 148.956C470.486 140.502 462.965 141.27 460.662 141.116C452.68 147.88 437.637 168.018 438.251 194.458C442.395 198.762 450.684 199.53 456.057 199.53Z" fill="#E1F3F6" />
        <path d="M510.242 278.543C513.466 277.621 516.536 276.238 518.532 273.471C509.014 258.099 474.169 201.375 472.327 196.918C472.327 196.764 472.174 196.61 472.174 196.61C467.108 199.377 460.968 200.299 454.981 200.146C449.762 199.992 442.394 199.223 438.096 195.38C438.096 195.688 438.096 195.995 438.096 196.149C438.863 210.906 487.831 260.251 507.172 279.158C508.4 279.158 509.321 278.851 510.242 278.543Z" fill="#00396A" />
        <path d="M409.09 184.16C407.708 184.16 406.48 184.006 405.099 183.545C402.489 182.623 399.112 180.471 397.423 175.09C396.502 172.016 398.344 168.634 401.415 167.712C404.485 166.79 407.862 168.634 408.783 171.709C408.936 172.016 408.936 172.323 409.09 172.477C409.704 172.477 412.006 172.324 417.686 168.942C422.752 166.021 428.738 161.563 434.571 157.259C444.396 150.034 453.606 143.117 461.281 140.811C464.351 139.888 467.728 141.579 468.649 144.654C469.57 147.728 467.882 151.11 464.812 152.032C458.978 153.877 450.229 160.487 441.633 166.79C429.045 176.013 417.993 184.16 409.09 184.16Z" fill="#FFDDAA" />
        <path d="M420.142 179.854C419.374 176.319 418.607 172.629 416.458 169.709C411.699 172.322 409.704 172.476 409.09 172.476C409.09 172.322 408.936 172.168 408.783 171.707C407.862 168.633 404.638 166.788 401.415 167.71C398.344 168.632 396.502 171.861 397.423 175.089C399.112 180.623 402.489 182.775 405.099 183.543C406.327 184.005 407.708 184.158 409.09 184.158C412.467 184.158 416.304 182.929 420.296 180.93C420.296 180.469 420.142 180.162 420.142 179.854Z" fill="#FFDDAA" />
        <path d="M468.648 144.499C467.726 141.424 464.349 139.733 461.279 140.656C453.604 143.115 444.394 149.879 434.57 157.104C428.737 161.408 422.75 165.866 417.684 168.787C417.377 168.94 417.224 169.094 417.07 169.094C419.219 172.015 423.057 186.311 423.21 186.618C429.504 183.39 459.284 160.639 465.731 153.722C468.033 151.57 469.569 147.573 468.648 144.499Z" fill="#E1F3F6" />
        <path d="M400.791 179.393C400.484 179.393 400.023 179.239 399.716 179.086C395.111 175.858 392.655 169.401 392.655 169.094C392.348 168.172 392.809 167.249 393.73 166.942C394.651 166.634 395.572 167.096 395.879 168.018C395.879 168.018 398.028 173.706 401.712 176.319C402.479 176.934 402.633 178.01 402.172 178.778C401.865 179.239 401.405 179.393 400.791 179.393Z" fill="#FFDDAA" />
        <path d="M400.778 175.243C400.164 175.243 399.703 174.935 399.396 174.474C397.247 171.4 395.405 163.867 395.252 163.099C395.098 162.176 395.559 161.254 396.48 160.947C397.401 160.793 398.322 161.254 398.629 162.176C399.089 164.328 400.778 170.17 402.313 172.322C402.927 173.091 402.62 174.167 401.852 174.781C401.545 175.089 401.238 175.243 400.778 175.243Z" fill="#FFDDAA" />
        <path d="M403.248 174.167C402.634 174.167 401.866 173.706 401.713 173.091C400.178 169.248 399.564 161.101 399.41 160.64C399.41 159.717 400.024 158.795 400.945 158.795C401.866 158.641 402.787 159.41 402.787 160.332C402.941 162.484 403.555 168.787 404.783 171.707C405.09 172.63 404.783 173.552 403.862 174.013C403.708 174.167 403.401 174.167 403.248 174.167Z" fill="#FFDDAA" />
        <path d="M405.882 173.399C405.114 173.399 404.347 172.938 404.193 172.169C403.119 167.865 404.04 163.561 404.193 163.407C404.347 162.485 405.421 161.87 406.342 162.177C407.263 162.331 407.877 163.253 407.57 164.329C407.57 164.483 406.803 168.019 407.57 171.4C407.877 172.323 407.263 173.245 406.342 173.552C406.189 173.399 406.035 173.399 405.882 173.399Z" fill="#FFDDAA" />
        <path d="M475.248 153.876C474.481 153.876 473.713 153.722 472.946 153.414C470.182 152.185 468.954 148.803 470.336 146.036C471.717 143.115 474.174 140.194 474.481 139.887C476.476 137.581 480.007 137.274 482.309 139.272C484.612 141.27 484.919 144.806 482.923 147.112C482.156 147.88 480.928 149.725 480.314 150.955C479.393 152.8 477.397 153.876 475.248 153.876Z" fill="#FFDDAA" />
        <path d="M476.005 151.11C476.619 151.878 477.387 152.493 478.001 153.108C478.922 152.493 479.689 151.725 480.15 150.802C480.764 149.573 481.992 147.882 482.759 146.959C484.755 144.653 484.448 141.118 482.145 139.12C479.843 137.121 476.312 137.429 474.317 139.734C474.163 139.888 473.703 140.349 473.242 141.118C473.242 144.807 473.703 148.497 476.005 151.11Z" fill="#FDCF89" />
        <path d="M494.275 144.961C492.126 150.341 485.986 152.801 480.613 150.649C475.24 148.496 472.784 142.348 474.933 136.967L477.696 130.204C479.845 124.823 485.986 122.364 491.358 124.516C496.731 126.668 499.187 132.817 497.038 138.197L494.275 144.961Z" fill="#FFDDAA" />
        <path d="M476.024 136.198C475.103 139.733 470.037 138.657 468.041 136.813C465.432 134.199 463.743 126.974 469.73 127.282C465.432 120.826 479.861 113.908 481.396 121.287C482.471 119.442 483.699 117.597 486.155 117.444C487.997 117.29 491.067 118.673 490.913 120.979C493.677 118.827 497.514 118.981 500.124 121.133C501.045 121.748 502.58 123.285 501.812 124.515C501.352 125.13 499.203 125.283 498.435 125.591C499.663 124.976 504.882 126.206 503.808 128.358C503.347 129.434 500.891 128.512 500.124 128.204C500.124 128.204 500.124 128.512 500.124 128.358C502.887 129.895 503.961 131.432 503.654 134.814C503.501 136.351 502.733 139.426 500.891 139.58C499.97 139.733 498.896 139.118 498.896 138.042C501.198 140.348 497.975 146.497 495.058 143.423C493.984 142.193 494.444 140.194 495.519 139.272C493.83 138.35 490.146 133.123 492.755 131.586C491.527 131.586 485.848 129.741 487.536 128.05C487.997 129.895 485.694 132.508 483.699 131.432C484.927 134.046 481.396 135.89 479.247 136.044C480.475 137.735 476.484 139.58 475.256 137.274L476.024 136.198Z" fill="#00396A" />
        <path d="M279.682 115.291C279.682 115.291 276.305 102.378 293.036 99.304L291.808 114.83L279.682 115.291Z" fill="#FDCF89" />
        <path d="M212.907 147.879C209.223 147.879 205.846 147.11 202.775 145.727C187.732 138.502 186.044 117.135 185.89 116.212C185.737 112.984 188.039 110.217 191.263 109.91C194.486 109.756 197.249 112.062 197.556 115.29C197.863 119.594 200.319 131.585 207.688 135.12C213.828 138.041 223.191 135.12 235.011 126.665C237.621 124.821 241.305 125.436 243.147 128.049C244.989 130.662 244.375 134.351 241.765 136.196C231.02 143.882 221.349 147.879 212.907 147.879Z" fill="#CDE5E8" />
        <path d="M244.218 158.795C245.907 154.49 247.442 150.186 249.13 145.882C247.902 145.728 246.674 145.575 245.6 145.421C239.92 144.345 235.008 142.039 231.17 138.503C227.179 134.968 224.109 130.51 221.96 124.976C219.811 119.596 218.583 113.293 218.276 106.376C217.969 99.4584 218.43 92.0798 219.965 84.3938C221.5 76.554 223.649 69.4828 226.565 63.1803C229.482 56.8777 232.859 51.6512 236.85 47.347C240.841 43.0429 245.293 39.9684 250.358 38.1238C255.271 36.2791 260.643 35.9717 266.323 37.0477C270.774 37.9701 274.766 39.661 278.296 42.1205C279.985 38.7387 281.827 35.5105 283.515 32.2824C285.511 28.4394 287.353 24.4426 289.195 20.5996C289.962 18.9087 290.576 17.2177 291.344 15.3731C285.511 12.145 279.064 9.68543 271.849 8.30194C261.718 6.30357 252.047 6.76473 243.144 9.37799C234.241 11.9912 226.105 16.6029 218.89 22.9054C211.676 29.208 205.535 37.0477 200.623 46.5784C195.558 56.1091 192.027 66.7159 189.725 78.3986C187.422 90.0814 186.961 101.303 188.036 112.064C189.264 122.824 191.874 132.355 196.325 140.809C200.623 149.264 206.61 156.489 213.978 162.33C220.886 167.71 228.868 171.4 238.232 173.552C240.227 168.787 242.376 163.867 244.218 158.795Z" fill="#42AB43" />
        <path d="M323.732 70.4044C322.504 59.644 319.894 50.1133 315.443 41.6586C311.145 33.204 305.158 25.9791 297.943 20.1377C295.948 18.6005 293.952 17.217 291.65 15.8335C291.036 17.5244 290.268 19.3691 289.501 21.06C287.659 25.0567 285.817 28.8998 283.821 32.7428C282.132 35.9709 280.29 39.3528 278.602 42.7347C279.216 43.1958 279.83 43.657 280.29 44.1181C284.282 47.6537 287.352 52.1116 289.501 57.6456C291.803 63.1795 293.031 69.3284 293.338 76.0921C293.645 83.0095 293.185 90.3882 291.65 98.2279C290.115 105.914 287.966 112.985 285.049 119.288C282.132 125.59 278.755 130.97 274.611 135.121C270.466 139.425 266.015 142.5 260.949 144.344C257.265 145.728 253.427 146.343 249.436 146.035C247.441 150.647 245.906 155.412 244.064 160.177C242.375 164.789 240.38 169.401 238.23 173.859C238.691 174.012 239.151 174.012 239.612 174.166C249.743 176.164 259.414 175.703 268.317 173.09C277.22 170.477 285.356 165.865 292.571 159.563C299.785 153.26 305.926 145.42 310.838 135.89C315.903 126.359 319.434 115.752 321.736 104.069C324.346 92.2328 324.96 81.1649 323.732 70.4044Z" fill="#005E01" />
        <path d="M258.033 180.316C247.901 178.317 239.152 174.474 231.783 168.633C224.415 162.791 218.582 155.72 214.284 147.112C209.986 138.657 207.223 128.973 205.995 118.366C204.767 107.606 205.381 96.5377 207.683 84.7012C209.986 73.0184 213.516 62.4116 218.582 52.881C223.648 43.3503 229.634 35.5105 236.849 29.2079C244.064 22.9054 252.046 18.4475 261.103 15.6805C270.006 13.0672 279.677 12.6061 289.808 14.6044C299.939 16.6028 308.689 20.4458 316.057 26.2872C323.425 32.1286 329.258 39.1998 333.71 47.8082C338.008 56.2628 340.771 65.9472 341.999 76.554C343.227 87.3144 342.613 98.3823 340.31 110.219C338.008 121.902 334.477 132.508 329.412 142.039C324.346 151.57 318.359 159.41 311.145 165.712C303.93 172.015 295.948 176.473 286.891 179.24C277.681 181.853 268.164 182.16 258.033 180.316ZM263.559 151.57C269.085 152.646 274.304 152.185 279.37 150.34C284.282 148.495 288.887 145.421 293.031 141.117C297.176 136.813 300.553 131.586 303.47 125.283C306.386 118.981 308.535 111.91 310.07 104.224C311.605 96.384 312.066 89.0054 311.759 82.0879C311.452 75.1705 310.07 69.0216 307.921 63.6414C305.619 58.1075 302.549 53.6496 298.711 50.114C294.72 46.5784 289.961 44.2726 284.435 43.1965C278.756 42.1205 273.383 42.4279 268.471 44.2726C263.559 46.1172 258.954 49.1916 254.962 53.4958C250.971 57.8 247.594 63.0265 244.678 69.3291C241.761 75.6316 239.612 82.7028 238.077 90.5426C236.542 98.2286 236.082 105.607 236.389 112.525C236.696 119.442 237.924 125.591 240.073 131.125C242.222 136.505 245.292 141.117 249.283 144.652C252.967 148.188 257.726 150.494 263.559 151.57Z" fill="#007901" />
        <path d="M361.653 93.9227L362.114 115.597C362.114 115.597 355.973 121.131 350.447 115.597L352.443 91.1558L361.653 93.9227Z" fill="#00396A" />
        <path d="M336.183 266.398C335.876 266.091 333.42 265.014 333.113 264.707C331.885 266.705 330.963 267.781 329.428 269.319C333.266 272.701 332.192 276.697 332.192 276.697C332.192 276.697 329.428 279.003 328.507 279.464C325.13 281.155 318.99 283.461 318.99 283.461L334.648 284.691L346.16 272.854C342.016 270.548 338.792 268.55 336.183 266.398Z" fill="#002E4F" />
        <path d="M275.078 267.32C272.622 266.859 269.398 268.858 267.403 267.32C266.942 271.625 267.096 274.084 267.096 274.084L244.07 283.615H273.543C273.85 280.54 273.236 277.466 273.697 274.545C273.85 272.854 274.771 268.704 275.078 267.32Z" fill="#002E4F" />
        <path d="M301.933 162.33L275.838 211.982C275.838 211.982 286.43 220.898 296.868 223.972L316.363 165.404L301.933 162.33Z" fill="#CDE5E8" />
        <path d="M330.497 270.549C332.032 269.012 333.414 267.014 334.642 265.015C327.888 259.174 324.664 253.179 315.454 243.033C311.616 238.729 321.747 214.441 324.204 210.291C328.655 202.912 329.269 197.685 332.646 185.08L307.165 194.611C301.024 216.439 293.963 238.114 301.178 248.567C308.7 259.481 321.901 268.397 328.348 272.855C328.502 273.009 330.344 270.395 330.497 270.549Z" fill="#002E4F" />
        <path d="M287.665 112.831C287.358 112.831 286.898 112.677 286.591 112.523C285.823 111.909 285.67 110.833 286.13 110.064C288.126 107.143 288.586 104.837 288.586 104.837C288.74 103.915 289.661 103.3 290.582 103.454C291.503 103.608 292.117 104.53 291.963 105.452C291.963 105.606 291.349 108.527 288.893 112.062C288.74 112.524 288.126 112.831 287.665 112.831Z" fill="#FFDDAA" />
        <path d="M330.801 190.46C338.323 169.554 344.924 136.812 347.226 123.745C345.845 123.13 344.617 122.823 343.542 122.516C341.24 121.901 338.63 121.132 336.328 121.901C335.1 122.208 334.179 122.823 333.104 123.438C332.183 126.205 331.722 128.664 331.722 128.664C331.722 128.664 315.451 159.87 304.859 181.083C313.916 183.235 322.666 186.156 330.801 190.46Z" fill="#F6EE9E" />
        <path d="M304.545 181.852C303.624 183.696 302.703 185.541 301.935 187.232C299.786 190.921 297.637 194.303 295.488 197.685C274.458 229.352 266.63 254.562 265.248 266.552C267.244 268.089 274.765 269.012 277.375 269.473C281.212 249.643 291.344 237.038 309.304 222.127C315.29 217.208 319.895 210.751 323.886 205.525C326.189 202.45 328.645 197.378 330.794 191.229C322.351 186.771 313.602 183.85 304.545 181.852Z" fill="#00396A" />
        <path d="M284.13 190.614C281.674 190.614 279.371 189.077 278.604 186.617C277.529 183.543 279.218 180.161 282.288 179.085C289.349 176.625 300.401 166.787 307.769 156.334C322.659 135.121 333.251 123.284 333.711 122.823C335.86 120.364 339.545 120.21 342.001 122.362C344.457 124.514 344.61 128.203 342.461 130.663C342.308 130.817 331.869 142.499 317.44 163.098C310.225 173.397 297.331 186.31 286.279 190.153C285.511 190.46 284.897 190.614 284.13 190.614Z" fill="#FFDDAA" />
        <path d="M343.233 125.743C343.08 125.743 342.926 125.743 342.773 125.743C339.703 125.589 337.4 122.822 337.707 119.748C338.014 116.52 339.242 112.984 339.242 112.523C340.163 109.602 343.387 108.065 346.303 109.141C349.22 110.063 350.755 113.291 349.68 116.212C349.373 117.288 348.759 119.287 348.606 120.67C348.452 123.591 345.996 125.743 343.233 125.743Z" fill="#FFDDAA" />
        <path d="M344.765 119.902C345.686 120.978 346.761 122.054 347.989 122.976C348.449 122.208 348.603 121.439 348.756 120.671C348.91 119.287 349.37 117.289 349.831 116.213C350.752 113.292 349.217 110.218 346.454 109.141C343.998 108.373 341.542 109.295 340.16 111.14C340.467 114.368 343.077 117.904 344.765 119.902Z" fill="#FDCF89" />
        <path d="M359.04 107.912C358.886 113.753 354.128 118.211 348.294 118.211C342.461 118.057 338.01 113.292 338.01 107.451L338.163 100.226C338.317 94.3844 343.075 89.9265 348.909 89.9265C354.742 90.0802 359.193 94.8456 359.193 100.687L359.04 107.912Z" fill="#FFDDAA" />
        <path d="M275.855 188.924C273.399 188.924 270.636 188.309 267.565 186.925C266.644 186.618 266.337 185.542 266.644 184.619C266.951 183.697 268.026 183.39 268.947 183.697C273.552 185.542 277.083 185.849 279.385 184.466C280.153 184.005 281.227 184.158 281.688 185.081C282.148 185.849 281.995 186.925 281.074 187.386C279.692 188.462 278.004 188.924 275.855 188.924Z" fill="#FFDDAA" />
        <path d="M277.99 191.383C275.227 191.383 273.078 190.921 270.468 190.306L269.7 190.153C268.779 189.999 268.165 188.923 268.472 188.001C268.626 187.078 269.7 186.463 270.621 186.771L271.389 186.925C275.534 188.001 278.297 188.616 284.437 187.078C285.358 186.771 286.279 187.386 286.586 188.308C286.893 189.23 286.279 190.153 285.358 190.46C282.288 191.075 279.985 191.383 277.99 191.383Z" fill="#FFDDAA" />
        <path d="M275.847 185.541C270.474 185.541 266.637 182.159 266.483 182.005C265.716 181.39 265.716 180.314 266.33 179.546C266.944 178.777 268.018 178.777 268.786 179.392C268.939 179.546 272.93 183.081 278.303 181.698C279.224 181.39 280.145 182.005 280.452 182.927C280.759 183.85 280.145 184.772 279.224 185.079C277.996 185.387 276.768 185.541 275.847 185.541Z" fill="#FFDDAA" />
        <path d="M279.371 183.696H279.218C273.385 183.081 270.928 178.777 270.775 178.623C270.314 177.855 270.621 176.779 271.389 176.318C272.156 175.856 273.231 176.164 273.692 176.932C273.845 177.086 275.38 179.853 279.371 180.314C280.292 180.468 281.06 181.237 280.906 182.159C281.06 182.928 280.292 183.696 279.371 183.696Z" fill="#FFDDAA" />
        <path d="M300.093 115.29C299.94 115.29 299.94 115.29 299.786 115.29C296.87 114.829 289.041 113.599 287.353 113.599C286.432 113.599 285.664 112.83 285.664 111.908C285.664 110.986 286.432 110.217 287.353 110.217C289.809 110.217 299.326 111.754 300.4 111.908C301.321 112.062 301.935 112.984 301.782 113.906C301.628 114.675 300.861 115.29 300.093 115.29Z" fill="#FFDDAA" />
        <path d="M298.875 118.98C298.722 118.98 298.568 118.98 298.415 118.98C298.261 118.98 290.126 116.674 286.288 116.521C285.367 116.521 284.6 115.598 284.6 114.676C284.6 113.754 285.521 112.985 286.442 112.985C290.586 113.293 298.875 115.445 299.336 115.598C300.257 115.906 300.871 116.828 300.564 117.75C300.411 118.365 299.643 118.98 298.875 118.98Z" fill="#FFDDAA" />
        <path d="M298.102 122.669C297.949 122.669 297.795 122.669 297.642 122.515C289.813 120.056 287.971 119.902 286.896 119.748C286.436 119.748 285.975 119.748 285.515 119.594C284.594 119.287 283.98 118.365 284.287 117.442C284.594 116.52 285.515 115.905 286.436 116.213C286.743 116.213 286.896 116.366 287.203 116.366C288.431 116.52 290.581 116.674 298.716 119.287C299.637 119.594 300.098 120.517 299.791 121.439C299.484 122.208 298.87 122.669 298.102 122.669Z" fill="#FFDDAA" />
        <path d="M294.725 125.437C294.571 125.437 294.571 125.437 294.725 125.437C286.743 124.822 286.436 120.671 286.436 120.21L289.966 120.056C289.966 120.056 289.966 120.056 289.966 119.903C289.966 119.903 290.273 121.594 294.878 121.901C295.799 121.901 296.567 122.823 296.413 123.746C296.413 124.822 295.646 125.437 294.725 125.437Z" fill="#FFDDAA" />
        <path d="M288.126 123.437C288.126 123.437 286.591 122.669 284.289 122.669C282.293 122.669 279.684 119.287 279.684 115.905C279.684 115.905 278.763 105.759 289.508 110.371L288.126 123.437Z" fill="#FFDDAA" />
        <path d="M322.047 153.72L307.004 228.121C307.004 228.121 330.336 233.809 344.766 225.508L348.45 122.054C348.296 122.208 332.332 116.52 322.047 153.72Z" fill="#CDE5E8" />
        <path d="M325.585 194.764L340.014 193.996C340.014 193.996 340.321 216.132 331.725 215.209C331.725 215.209 319.905 217.823 325.585 194.764Z" fill="#E1F3F6" />
        <path d="M343.685 120.363C342.15 118.979 340.001 118.211 337.852 118.211C335.396 118.211 333.094 119.287 331.405 121.131C330.944 121.593 320.353 133.429 305.309 154.95C304.388 156.333 303.16 157.717 302.086 159.254L300.551 161.099L302.239 162.79C304.081 164.634 305.923 166.479 307.765 168.324C308.686 169.246 309.761 170.322 310.682 171.244L312.831 173.396L314.827 171.091C316.515 169.092 318.204 166.94 319.585 164.942C334.015 144.497 344.299 132.814 344.453 132.814C347.523 129.125 347.216 123.591 343.685 120.363Z" fill="#E1F3F6" />
        <path d="M304.119 154.261L298.271 160.779L313.459 174.443L319.306 167.924L304.119 154.261Z" fill="#F8FDFF" />
        <path d="M327.744 112.063L341.099 117.29C341.099 117.29 346.779 89.7736 342.634 85.6231C342.48 85.6231 327.744 84.2397 327.744 112.063Z" fill="#00396A" />
        <path d="M343.247 90.3876C343.707 91.1562 353.992 97.9199 362.128 96.0752C362.128 96.0752 360.746 83.9313 341.865 85.6222L343.247 90.3876Z" fill="#00396A" />
        <path d="M351.213 103.453C351.213 106.681 348.604 109.294 345.38 109.294C342.156 109.294 339.547 106.681 339.547 103.453C339.547 100.225 342.156 97.6116 345.38 97.6116C348.604 97.6116 351.213 100.225 351.213 103.453Z" stroke="#DDDD67" stroke-width="1.33226" stroke-miterlimit="10" />
        <path d="M364.268 104.53C364.268 107.297 361.965 109.603 359.202 109.603C356.439 109.603 354.137 107.297 354.137 104.53C354.137 101.763 356.439 99.4575 359.202 99.4575C361.965 99.4575 364.268 101.763 364.268 104.53Z" stroke="#DDDD67" stroke-width="1.15895" stroke-miterlimit="10" />
        <path d="M351.213 103.454C351.213 103.454 353.055 102.686 354.283 103.915" stroke="#DDDD67" stroke-width="1.33226" stroke-miterlimit="10" />
        <path d="M183.13 113.6C182.976 113.6 182.823 113.6 182.669 113.6C179.599 113.446 177.297 110.679 177.604 107.604C177.911 104.376 179.139 100.841 179.139 100.38C180.06 97.4589 183.283 95.9217 186.2 96.9977C189.116 97.92 190.651 101.148 189.577 104.069C189.27 105.145 188.656 107.143 188.502 108.527C188.349 111.294 186.046 113.6 183.13 113.6Z" fill="#FFDDAA" />
        <path d="M179.895 267.168C189.105 271.473 196.626 271.319 197.24 271.473C203.227 260.866 221.187 231.966 204.762 208.447L181.583 195.996C181.583 195.842 204.762 220.13 179.895 267.168Z" fill="#002E4F" />
        <path d="M191.869 276.699C191.869 276.699 194.018 272.395 195.707 269.474C194.479 269.321 188.799 269.167 186.496 268.245C184.347 272.703 179.589 279.159 176.365 283.617H207.526L191.869 276.699Z" fill="#002E4F" />
        <path d="M230.243 209.677C226.405 202.145 198.314 179.548 195.705 166.482C195.551 165.406 195.244 164.176 195.091 162.946C190.639 165.406 184.652 166.02 180.047 166.174C174.828 166.482 171.605 166.789 166.539 164.33C167 172.323 165.618 180.163 170.377 188.31C179.74 204.604 210.748 218.439 214.125 223.819C220.879 234.426 223.335 257.331 225.638 271.319C229.322 271.319 237.918 271.166 241.449 269.936C240.374 262.865 242.37 233.35 230.243 209.677Z" fill="#00396A" />
        <path d="M239.917 275.316C239.917 275.316 238.843 271.166 238.536 269.321C235.005 270.551 232.856 269.321 229.172 269.321C229.786 272.857 229.325 279.313 229.632 283.156H259.105L239.917 275.316Z" fill="#002E4F" />
        <path d="M180.358 170.632C184.81 170.478 194.327 169.248 198.011 165.405C197.244 159.256 194.481 149.264 195.402 141.271C198.625 114.216 185.424 106.069 185.424 106.069C185.424 106.069 179.284 101.765 174.372 106.837C167.004 114.523 159.635 136.198 160.403 168.172C165.775 170.785 174.986 170.785 180.358 170.632Z" fill="#CDE5E8" />
        <path d="M248.208 179.086C247.748 179.086 247.287 179.086 246.826 178.932C225.489 174.013 207.53 161.254 194.635 142.039C184.965 127.59 181.741 114.677 181.587 114.216C180.82 111.141 182.662 107.913 185.886 107.145C188.956 106.376 192.179 108.221 192.947 111.449C192.947 111.756 196.017 123.285 204.613 136.044C215.819 152.8 231.016 163.406 249.436 167.711C252.66 168.479 254.502 171.554 253.888 174.782C253.274 177.241 250.818 179.086 248.208 179.086Z" fill="#FFDDAA" />
        <path d="M235.16 161.407C223.493 155.873 213.516 146.958 205.533 135.121C197.091 122.516 194.021 110.987 194.021 110.833C193.253 107.759 190.49 105.607 187.267 105.607C186.652 105.607 186.192 105.607 185.578 105.76C183.736 106.222 182.354 107.298 181.28 108.989C180.359 110.526 180.052 112.37 180.512 114.215V114.369C181.433 118.366 185.117 132.2 193.714 145.267C203.998 160.793 218.121 172.322 234.239 178.778C234.392 178.778 234.546 178.778 234.546 178.778C234.699 178.778 235.006 178.778 235.16 178.624C235.467 178.47 235.62 178.163 235.62 177.702V162.176C235.774 162.022 235.62 161.561 235.16 161.407Z" fill="#E1F3F6" />
        <path d="M252.513 179.547C250.671 179.547 248.675 179.393 246.526 178.932C245.605 178.779 244.991 177.856 245.145 176.934C245.298 176.011 246.219 175.397 247.14 175.55C252.359 176.626 256.504 176.165 259.881 174.474C260.648 174.013 261.723 174.321 262.183 175.243C262.644 176.012 262.337 177.088 261.416 177.549C258.96 178.779 255.89 179.547 252.513 179.547Z" fill="#FFDDAA" />
        <path d="M252.827 175.703C252.06 175.703 251.138 175.703 250.371 175.55C249.45 175.396 248.836 174.474 248.836 173.551C248.989 172.629 249.91 172.014 250.831 172.014C256.358 172.783 261.423 170.016 261.577 169.862C262.344 169.401 263.419 169.708 263.879 170.477C264.34 171.245 264.033 172.321 263.265 172.783C262.958 173.09 258.353 175.703 252.827 175.703Z" fill="#FFDDAA" />
        <path d="M251.292 172.322C250.985 172.322 250.678 172.322 250.524 172.322C249.603 172.322 248.836 171.4 248.836 170.478C248.836 169.555 249.757 168.787 250.678 168.787C254.823 169.094 256.972 166.481 256.972 166.481C257.586 165.712 258.66 165.559 259.428 166.174C260.195 166.788 260.349 167.865 259.735 168.633C259.428 168.787 256.665 172.322 251.292 172.322Z" fill="#FFDDAA" />
        <path d="M245.306 169.402C244.385 169.402 243.617 168.633 243.617 167.711C243.617 166.788 244.385 166.02 245.306 166.02C245.459 166.02 248.836 165.866 250.832 163.099C251.446 162.33 252.52 162.177 253.288 162.638C254.055 163.253 254.209 164.329 253.748 165.097C250.678 169.248 245.459 169.402 245.306 169.402Z" fill="#FFDDAA" />
        <path d="M239.612 130.356C238.998 130.356 238.537 130.049 238.23 129.587C235.621 126.052 235.16 122.977 235.16 122.977C235.007 122.055 235.621 121.133 236.542 120.979C237.463 120.825 238.384 121.44 238.537 122.363C238.537 122.363 238.998 124.668 240.993 127.589C241.607 128.358 241.3 129.434 240.533 130.049C240.379 130.202 239.919 130.356 239.612 130.356Z" fill="#FFDDAA" />
        <path d="M239.917 130.972C239.764 130.972 239.764 130.972 239.61 130.972C235.773 130.203 228.405 130.203 228.251 130.203C227.33 130.203 226.562 129.434 226.562 128.512C226.562 127.59 227.33 126.821 228.251 126.821C228.558 126.821 236.08 126.821 240.224 127.59C241.145 127.743 241.759 128.666 241.606 129.588C241.452 130.51 240.838 130.972 239.917 130.972Z" fill="#FFDDAA" />
        <path d="M227.337 134.662C226.416 134.662 225.802 134.047 225.649 133.125C225.495 132.202 226.263 131.28 227.184 131.126C228.258 130.973 237.929 129.743 241.152 130.358C242.073 130.511 242.687 131.434 242.534 132.356C242.38 133.278 241.459 133.893 240.538 133.74C238.543 133.432 231.635 134.047 227.644 134.508C227.491 134.662 227.491 134.662 227.337 134.662Z" fill="#FFDDAA" />
        <path d="M229.638 138.503C228.87 138.503 228.103 137.888 227.949 137.12C227.796 136.197 228.41 135.275 229.331 135.121C233.475 134.353 234.703 133.891 236.699 133.277C237.62 132.969 238.848 132.662 240.537 132.047C241.458 131.739 242.379 132.354 242.686 133.277C242.993 134.199 242.379 135.121 241.458 135.429C239.769 135.89 238.695 136.351 237.62 136.658C235.471 137.273 234.243 137.734 229.945 138.657C229.791 138.503 229.638 138.503 229.638 138.503Z" fill="#FFDDAA" />
        <path d="M233.321 141.272C232.554 141.272 231.786 140.657 231.633 139.888C231.479 138.966 232.093 138.044 233.014 137.89C238.541 136.967 239.769 134.815 239.769 134.815C240.076 133.893 240.997 133.432 241.918 133.739C242.839 134.047 243.299 134.969 242.992 135.891C242.839 136.506 241.304 140.042 233.628 141.272C233.475 141.272 233.475 141.272 233.321 141.272Z" fill="#FFDDAA" />
        <path d="M239.921 139.272L238.539 125.129C238.539 125.129 244.679 125.591 244.679 132.047C244.833 134.199 244.219 136.658 239.921 139.272Z" fill="#FFDDAA" />
        <path d="M201.082 93.3099C202.617 99.1513 199.087 105.3 193.254 106.837C187.42 108.375 181.28 104.839 179.745 98.9976L177.75 91.619C176.215 85.7776 179.745 79.6288 185.578 78.0916C191.412 76.5544 197.552 80.09 199.087 85.9314L201.082 93.3099Z" fill="#FFDDAA" />
        <path d="M200.007 89.313C199.239 91.6188 198.011 93.9246 196.169 95.6155C191.718 99.4586 184.964 100.535 179.438 98.2288L179.591 98.9974C181.126 104.839 187.266 108.374 193.099 106.837C198.932 105.3 202.463 99.1511 200.928 93.3097L200.007 89.313Z" fill="#00396A" />
        <path d="M179.745 98.5362C175.601 95.9229 171.149 85.7774 177.596 83.4715C181.587 82.0881 180.052 94.5394 180.052 98.075L179.745 98.5362Z" fill="#00396A" />
        <path d="M176.366 84.3939C171.301 80.7046 177.594 70.4053 190.028 70.4053C197.703 70.4053 197.703 76.2467 192.331 79.4748C187.879 82.0881 181.125 80.5509 177.748 85.0088L176.366 84.3939Z" fill="#00396A" />
        <path d="M200.007 91.6179C198.319 89.0046 194.788 83.6244 193.407 80.55C192.486 78.5516 191.411 75.3234 195.249 77.6293C198.472 79.6276 200.161 86.5451 200.007 89.9269V91.6179Z" fill="#00396A" />
        <path d="M112.52 116.213C110.678 117.904 108.529 119.595 106.84 121.594L81.9727 173.705L100.547 149.724L120.195 108.835C117.585 111.448 115.129 113.908 112.52 116.213Z" fill="#005D01" />
        <path d="M97.7871 107.604C99.6292 105.145 101.318 102.224 103.467 99.6108L59.8717 78.2437L18.4258 82.3941L91.033 115.905C93.182 113.292 95.6381 110.525 97.7871 107.604Z" fill="#005E01" />
        <path d="M103.922 100.073C101.62 102.994 99.7775 106.376 97.4749 109.143C95.4794 111.602 93.4838 114.062 91.4883 116.368L124.492 131.586L136.465 115.907L103.922 100.073Z" fill="#42AB43" />
        <path d="M54.3403 160.638L67.0811 133.891L0 101.763L10.7453 79.32L121.114 14.1423L151.201 28.5921L106.992 121.286L125.259 130.048L112.979 155.719L94.7117 146.957L81.9709 173.705L54.3403 160.638ZM103.922 56.7231L102.233 55.8007L43.1345 90.388L79.3614 107.758L103.922 56.7231Z" fill="#007901" />
        <path d="M125.258 129.894L136.464 115.905L127.714 135.274L112.518 156.641L125.258 129.894Z" fill="#005D01" />
        <path d="M120.958 108.219L164.553 12.605L151.352 28.4382L107.143 121.132C108.217 119.902 120.344 108.834 120.958 108.219Z" fill="#005E01" />
        <path d="M10.749 79.1662L30.551 61.9495L138.311 0L121.272 13.9886L10.749 79.1662Z" fill="#42AB43" />
        <path d="M121.266 13.9901L151.352 28.4398L164.554 12.6066L138.305 0.00146484L121.266 13.9901Z" fill="#4FC250" />
        <path d="M84.2744 83.778C80.5903 81.1648 77.9807 82.2408 76.2922 82.5483C74.2966 82.8557 72.9151 81.7797 72.9151 81.7797C72.1476 81.3185 71.0731 81.4722 70.459 82.2408C69.9985 83.0094 70.152 84.0855 70.9195 84.7004C71.0731 84.7004 72.4546 85.7764 75.2177 86.3913C72.3011 89.0045 69.3845 92.2327 69.231 92.3864C68.617 93.155 68.617 94.2311 69.3845 94.8459C69.6915 95.1534 70.152 95.3071 70.6125 95.3071C71.0731 95.3071 71.5336 95.1534 71.8406 94.6922C71.8406 94.6922 73.9896 92.3864 76.1387 90.2343C73.9896 92.8476 71.9941 95.6146 71.6871 96.2294C71.0731 96.998 71.3801 98.0741 72.1476 98.689C72.4546 98.8427 72.7616 98.9964 73.2221 98.9964C73.6826 98.9964 74.2966 98.689 74.6036 98.2278C75.6782 96.6906 77.3667 94.5385 78.9017 92.5401C78.2877 93.6162 77.6737 94.9997 76.1387 97.3055C75.6782 98.0741 75.8317 99.1501 76.7527 99.765C77.0597 99.9188 77.3667 100.072 77.6737 100.072C78.2877 100.072 78.7482 99.765 79.2087 99.3039C79.8228 98.3815 80.2833 97.6129 80.7438 96.8443C80.4368 97.6129 80.7438 98.5353 81.3578 98.9964C81.6648 99.1501 81.9718 99.3039 82.2788 99.3039C82.8928 99.3039 83.3533 98.9964 83.6603 98.5353C85.3489 96.0757 86.1164 94.0774 86.4234 92.3864C87.1909 87.4674 85.9629 85.1615 84.2744 83.778ZM80.7438 96.0757C81.3578 94.9997 81.6648 94.3848 82.1253 93.6162C81.8183 94.2311 81.3578 94.9997 80.7438 96.0757Z" fill="#FFDDAA" />
        <path d="M31.318 103.456C25.7919 103.148 17.9632 113.909 26.0989 116.829C18.7307 116.676 16.8887 127.129 23.6428 129.896C11.8231 127.59 13.2046 142.962 18.7307 148.496C14.8931 147.881 11.0555 151.57 11.6696 155.413C12.2836 159.41 13.8186 164.944 23.6428 167.865C33.4671 170.785 39.4537 165.251 44.3658 160.025C47.4359 166.481 62.9398 157.719 61.2512 150.187C67.0844 152.185 72.3035 145.575 72.457 140.349C72.6105 135.122 68.9264 131.279 65.5493 127.897C68.0054 125.745 68.4659 121.595 67.8519 118.366C66.7773 113.14 60.3302 112.679 60.3302 108.528C60.3302 107.145 60.6372 102.533 59.4092 100.535C57.4136 97.3067 54.0365 94.2323 49.7384 95.7695C31.318 87.1611 31.318 103.456 31.318 103.456Z" fill="#00396A" />
        <path d="M41.9072 271.471C36.9951 270.549 36.0741 270.856 31.162 269.626L19.8027 283.615H64.0118L38.3767 276.697L41.9072 271.471Z" fill="#002E4F" />
        <path d="M124.184 275.468L121.574 272.086C119.272 273.624 116.816 274.853 114.053 275.776L120.96 283.615H146.903L124.184 275.468Z" fill="#002E4F" />
        <path d="M26.5537 271.471C31.4658 272.701 36.5315 273.931 41.4436 274.853L77.9774 229.813L71.6838 211.981L46.0487 182.467L53.4168 224.279L26.5537 271.471Z" fill="#002E4F" />
        <path d="M76.1433 170.016C66.1656 167.402 52.5037 170.784 42.833 174.474C42.833 174.474 48.8196 198.3 52.8107 205.371C64.17 226.124 113.752 277.774 113.752 277.774C116.515 276.852 121.734 273.009 124.036 271.471L76.1433 170.016Z" fill="#00396A" />
        <path d="M41.446 183.697C51.1167 180.007 70.4582 173.859 80.4359 176.472C80.4359 176.472 67.2346 145.42 61.248 138.656C57.2569 134.045 35.1523 120.979 35.1523 138.042C35.3058 161.253 41.446 183.697 41.446 183.697Z" fill="#CDE5E8" />
        <path d="M50.04 136.505C49.8865 136.505 49.733 136.659 49.5795 136.659C46.6629 137.427 43.5928 135.736 42.8253 132.816C42.0578 129.741 41.9043 125.898 41.9043 125.437C41.9043 122.363 44.3604 119.903 47.4304 119.903C50.5005 119.903 52.9566 122.363 52.9566 125.437C52.9566 126.513 53.1101 128.665 53.4171 130.049C54.3381 132.662 52.8031 135.583 50.04 136.505Z" fill="#FFDDAA" />
        <path d="M53.7337 130.969C53.7337 130.508 53.7337 130.201 53.5802 129.74C53.2731 128.51 53.1196 126.358 53.1196 125.128C53.1196 122.054 50.6636 119.594 47.5935 119.594C44.5234 119.594 42.0674 122.054 42.0674 125.128C42.0674 125.128 42.0674 125.435 42.0674 125.589C44.8304 128.971 49.282 130.354 53.7337 130.969Z" fill="#FDCF89" />
        <path d="M59.1021 114.369C60.9442 119.903 57.8741 125.744 52.348 127.589C46.8218 129.433 40.9887 126.359 39.1467 120.825L36.8441 113.907C35.0021 108.373 38.0721 102.532 43.5983 100.687C49.1244 98.8428 54.9575 101.917 56.7996 107.451L59.1021 114.369Z" fill="#FFDDAA" />
        <path d="M120.807 108.835C120.654 108.681 120.347 108.527 120.04 108.527C120.347 108.22 120.5 107.913 120.807 107.605C121.421 106.837 121.421 105.76 120.654 105.146C120.5 104.992 120.5 104.992 120.347 104.992C120.807 104.223 120.807 103.301 120.04 102.686C119.579 102.225 118.812 102.071 118.198 102.379C118.965 101.61 119.426 100.995 119.579 100.995C120.193 100.227 120.193 99.1505 119.426 98.5356C118.658 97.9207 117.584 97.9207 116.97 98.6893C116.97 98.6893 113.132 102.994 109.294 105.146C107.606 102.532 105.303 101.764 105.303 101.61C104.382 101.303 103.461 101.764 103.154 102.532C102.847 103.455 103.308 104.377 104.075 104.838C104.075 104.838 105.457 105.453 106.531 106.99C106.378 107.451 106.224 107.913 106.071 108.527C80.5892 142.961 46.9719 130.817 45.4368 130.202C42.3667 128.972 38.9897 130.51 37.9151 133.584C36.6871 136.658 38.2222 140.04 41.2922 141.27C41.7527 141.424 49.8884 144.498 61.7082 144.498C64.0108 144.498 66.6203 144.344 69.2299 144.037C81.9707 142.5 100.698 135.275 115.741 115.906C116.048 115.752 116.355 115.599 116.816 115.137C117.123 114.984 117.43 114.676 117.737 114.523C118.658 113.754 119.733 112.678 120.961 111.294C121.575 110.526 121.421 109.45 120.807 108.835Z" fill="#FFDDAA" />
        <path d="M73.9911 130.356L72.6096 130.663C69.079 131.432 65.5484 131.739 61.8643 131.739C52.9611 131.739 46.821 129.433 46.0535 129.28C45.2859 128.972 44.3649 128.818 43.5974 128.818C40.6808 128.818 38.2248 130.509 37.1503 133.276C36.5362 134.967 36.3827 136.966 37.3038 138.503C40.6808 144.498 49.8911 146.957 61.8643 146.957C64.4739 146.957 66.9299 146.804 69.5395 146.496C71.0745 146.343 72.6096 146.035 74.2981 145.728L75.3727 145.42C75.3727 145.42 73.9911 141.116 73.6841 136.658C73.3771 135.121 73.9911 130.356 73.9911 130.356Z" fill="#E1F3F6" />
        <path d="M36.8395 119.596C40.5236 117.29 41.7517 112.986 41.2911 108.682C44.5147 110.834 46.9708 109.45 48.9663 105.761C54.953 114.216 64.1632 101.61 51.7294 97.1525C41.7517 93.4632 31.9274 103.455 34.3835 113.447L36.8395 119.596Z" fill="#00396A" />
    </svg>

)