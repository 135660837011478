import React, { useState } from 'react'
import { Form, Input } from 'antd'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Banner = () => {
    const [searchText, setSearchText] = useState('')
    const navigate = useNavigate()
    const { t } = useTranslation()

    const onFinish = async () => {
        navigate(`/all-courses?${queryString.stringify({ search: searchText })}`)
    }

    return (
        <div className='home-banner'>
            <div className='left'>
                <h3>{t('home.leader')}</h3>
                <h2>{t('home.banner-text')}</h2>
                <p>{t('home.banner-paragraph')}</p>
                <Form className='search-wrap' onFinish={onFinish}>
                    <Input placeholder={t('home.search')} value={searchText} onChange={e => setSearchText(e.target.value)} />
                    <button className='yellow-btn'>{t('home.search')}</button>
                </Form>
            </div>
        </div>
    )
}

export default Banner