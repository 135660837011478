import { Col, Empty, Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import http from '../../../utils/axiosInterceptors'
import queryString from 'query-string'
import parse from 'html-react-parser'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../components/loader'
import Result from './result'
import { toast } from 'react-toastify'

const MainQuiz = (props) => {
    const { getCourseDetails } = props

    const params = useParams()
    const navigate = useNavigate()

    const courseId = params.courseId

    const [isLoading, setIsLoading] = useState(false)
    const [lessonDetail, setLessonDetail] = useState({})
    const [allSelectedAnswers, setAllSelectedAnswers] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [resultsInfo, setResultsInfo] = useState({})
    const [endLoading, setEndLoading] = useState(false)
    const [quizCount, setQuizCount] = useState(0)

    const matchAnswers = (questionId, answer) => {
        let existingObj = allSelectedAnswers.find(obj => obj.quiz_id === questionId);
        if (existingObj) {
            existingObj.answer = answer
        } else {
            let newObj = {
                quiz_id: questionId,
                answer
            }
            allSelectedAnswers.push(newObj)
        }
        setAllSelectedAnswers([...allSelectedAnswers,])
    }

    const getCourseLessonDetails = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`quiz/main/?enrolled_id=${courseId}`)
            setLessonDetail(response?.data?.data)
            setQuizCount(response?.data?.data?.quizes?.length)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const endQuiz = async () => {
        if (quizCount === allSelectedAnswers.length) {
            setEndLoading(true)
            try {
                const response = await http.post("/quiz/main/answer/", {
                    enrolled_course: courseId,
                    answers: allSelectedAnswers,
                })
                setResultsInfo(response?.data?.data)
                setIsOpenModal(true)
            } catch (error) {
                toast.error("Javoblarni to'liq belgilang")
            } finally {
                setEndLoading(false)
            }
        } else {
            toast.error("Barcha javoblarni belgilang!")
        }
    }

    const finishCourseLesson = async () => {
        navigate(`?${queryString.stringify({ lessonType: "comment", lessonId: undefined, sectionId: undefined })}`)
        getCourseDetails()
    };

    useEffect(() => {
        getCourseLessonDetails()

        // eslint-disable-next-line
    }, [])

    return (
        <div className='quiz-page'>
            {
                isLoading ? <Loader /> : (
                    <>
                        <h1>{lessonDetail?.main_quiz?.title}</h1>
                        <div>O'tish foizi: {lessonDetail?.main_quiz?.pass_percentage}%</div>
                        <div>Urinishlar soni: {lessonDetail?.main_quiz?.tried_count}</div>
                        <div style={{ marginBottom: '30px' }}>Testlar soni: {lessonDetail?.main_quiz?.quiz_count}</div>
                        {
                            lessonDetail?.quizes?.map((item, index) => {
                                return (
                                    <div className='one-quiz' key={index}>
                                        <div className='top'>
                                            <h3><strong style={{ marginRight: '10px' }}>{index + 1}.</strong> {item?.question ? parse(item?.question) : null}</h3>
                                            <span>{item?.point} ball</span>
                                        </div>
                                        <div className='answers'>
                                            <Radio.Group
                                                onChange={(e) => matchAnswers(item?.id, e.target.value)}
                                                buttonStyle='solid'
                                            >
                                                {
                                                    item?.options?.map((option) => {
                                                        return (
                                                            <Radio key={Object.keys(option)} value={Object.keys(option)[0]}>{Object.keys(option)[0]}) {Object.values(option)}</Radio>
                                                        )
                                                    })
                                                }
                                            </Radio.Group>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            lessonDetail?.quizes?.length === 0 ? <Empty /> : null
                        }
                        <Col
                            span={8}
                            style={{ marginLeft: 'auto' }}
                        >
                            <button className='green-btn' onClick={endQuiz} disabled={endLoading}>
                                {endLoading ? "Iltimos kuting..." : "Tugatish"}
                            </button>
                        </Col>
                    </>
                )
            }
            <Result
                setIsOpenModal={setIsOpenModal}
                isOpenModal={isOpenModal}
                finishCourseLesson={finishCourseLesson}
                resultsInfo={resultsInfo}
                quizType="main"
            />
        </div>
    )
}

export default MainQuiz