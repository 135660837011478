import React, { useEffect, useState } from 'react'
import './view-course.css'
import CourseSidebar from '../../components/course-sidebar'
import Main from './course-types/main'
import queryString from 'query-string'
import { Link, useParams } from 'react-router-dom'
import http from '../../utils/axiosInterceptors'
import Loader from '../../components/loader'
import VideoCourse from './course-types/video'
import Quiz from './course-types/quiz'
import Assigments from './course-types/assigments'
import MainQuiz from './course-types/main-quiz'
import Comment from './course-types/comment'

const ViewCourse = () => {
    const params = useParams()
    const courseId = params.courseId
    const { lessonType, lessonId } = queryString.parse(window.location.search)


    const [courseDetail, setCourseDetail] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [courseSections, setCourseSections] = useState([])

    const getCourseDetails = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`/cource/enroll/detail/${courseId}/`)
            setCourseDetail(response?.data?.data)
            setCourseSections(
                response?.data?.data?.course_sections?.map((item, index) => {
                    if (index === 0) {
                        return { ...item, is_section_opened: true }
                    } else {
                        const prevSection = response?.data?.data?.course_sections[index - 1]

                        if (prevSection.is_next_section_locked === false) {
                            return { ...item, is_section_opened: true }
                        } else {
                            return { ...item, is_section_opened: false }
                        }
                    }
                })
            )
            setCourseSections(
                response?.data?.data?.course_sections?.map((item, index) => {
                    return { ...item, is_section_opened: true }
                })
            )
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCourseDetails()
        // eslint-disable-next-line
    }, [lessonType, lessonId])

    return (
        <div className='container'>
            <div className='top-navigator'>
                <Link to="/">
                    <div>
                        Bosh sahifa
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <Link to="/my-courses">
                    <div>
                        Mening kurslarim
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 7L14 12L10 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </Link>
                <div className='active'>
                    {courseDetail?.course_title}
                </div>
            </div>

            <div className='view-course-page'>
                {
                    isLoading ? <Loader /> : (
                        <>
                            <CourseSidebar
                                courseSections={courseSections}
                                courseProgress={courseDetail?.course_progress}
                                isCertificatCreated={courseDetail?.is_certificate_created}
                                certificateFile={courseDetail?.enroll_course_certificate_file}
                            />

                            <div className='right box'>
                                {
                                    !lessonId && lessonType !== "video" && lessonType !== "quiz" && lessonType !== "assignment" && lessonType !== "main-quiz" && lessonType !== "comment" ? (
                                        <Main
                                            courseDetail={courseDetail}
                                            courseSections={courseSections}
                                        />
                                    ) : null
                                }

                                {
                                    lessonId && lessonType === "video" ? (
                                        <VideoCourse
                                            courseSections={courseSections}
                                            getCourseDetails={getCourseDetails}
                                        />
                                    ) : null
                                }

                                {
                                    lessonId && lessonType === "quiz" ? (
                                        <Quiz
                                            courseSections={courseSections}
                                            getCourseDetails={getCourseDetails}
                                        />
                                    ) : null
                                }

                                {
                                    lessonType === "main-quiz" ? (
                                        <MainQuiz getCourseDetails={getCourseDetails} />
                                    ) : null
                                }

                                {
                                    lessonId && lessonType === "assignment" ? (
                                        <Assigments
                                            courseSections={courseSections}
                                            getCourseDetails={getCourseDetails}
                                        />
                                    ) : null
                                }

                                {
                                    lessonType === "comment" ? (
                                        <Comment
                                            courseDetail={courseDetail}
                                        />
                                    ) : null
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default ViewCourse